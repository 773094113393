import React, { useState, useEffect } from 'react';
import { Input, Button, Message, Checkbox, Tooltip } from '@arco-design/web-react';
import { motion } from 'framer-motion';
import { API_BASE_URL } from '../config';
import { IconClose, IconInfoCircle } from '@arco-design/web-react/icon';
import { TITLE_COLOR } from '../App';
import { useLanguage } from '../contexts/LanguageContext';

const PRIMARY_BUTTON_STYLE = {
  height: '40px',
  borderRadius: '8px',
  background: 'linear-gradient(135deg, rgba(52, 161, 250, 1) 0%, rgba(28, 231, 131, 1) 100%)',
  border: 'none',
  transition: 'opacity 0.3s ease',
  fontWeight: '600'
};

const TEXT_BUTTON_STYLE = {
  color: '#3491FA',
  padding: '0 4px',
  height: 'auto',
  lineHeight: 'inherit'
};

const Login = ({ onSuccess, onClose, defaultActiveTab = 'login' }) => {
  const { t } = useLanguage();
  const [activeTab, setActiveTab] = useState(defaultActiveTab);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [showVerification, setShowVerification] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [resetCode, setResetCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [resetStep, setResetStep] = useState(1); // 1: 输入邮箱, 2: 验证码, 3: 新密码
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [invitationCode, setInvitationCode] = useState('');
  const [verificationEmail, setVerificationEmail] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const inviteParam = urlParams.get('invite');
    const shouldRegister = urlParams.get('register');
    
    if (inviteParam) {
      setInvitationCode(inviteParam);
      // 如果存在 register 参数，切换到注册标签
      if (shouldRegister === 'true') {
        setActiveTab('register');
      }
      
      // 清除 URL 参数但不刷新页面
      const newUrl = window.location.pathname;
      window.history.pushState({}, '', newUrl);
    }

    // 从 localStorage 获取待处理的邀请码
    const pendingInviteCode = localStorage.getItem('pendingInviteCode');
    if (pendingInviteCode) {
      setInvitationCode(pendingInviteCode);
      setActiveTab('register');
    }
  }, []);

  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);

    return {
      valid: password.length >= minLength && hasUpperCase && hasLowerCase && hasNumber,
      message: t('auth.messages.passwordRequirements')
    };
  };

  const handleLogin = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_BASE_URL}/api/auth/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
      });
      
      const data = await response.json();
      
      if (response.status === 403 && data.needVerification) {
        Message.info(t('auth.messages.verificationRequired'));
        setShowVerification(true);
        setActiveTab('register');
        return;
      }
      
      if (!response.ok) {
        const errorMessages = {
          'Invalid password': 'auth.errors.invalidPassword',
          'Account does not exist': 'auth.errors.accountNotExist',
          'Account not verified': 'auth.errors.accountNotVerified',
          'User not found': 'auth.errors.userNotFound'
        };
        
        throw new Error(t(errorMessages[data.error] || 'auth.errors.serverError'));
      }
      
      localStorage.setItem('token', data.access_token);
      localStorage.setItem('userEmail', email);
      localStorage.setItem('userId', data.user_id);
      Message.success(t('auth.messages.loginSuccess'));
      onSuccess?.();
    } catch (error) {
      Message.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  const handleRegister = async () => {
    try {
      // 检查服务条款
      if (!agreedToTerms) {
        Message.error(t('auth.terms.error'));
        return;
      }

      // 验证密码
      const passwordValidation = validatePassword(password);
      if (!passwordValidation.valid) {
        Message.error(passwordValidation.message);
        return;
      }

      setLoading(true);
      const response = await fetch(`${API_BASE_URL}/api/auth/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password,
          invitation_code: invitationCode
        }),
      });

      const data = await response.json();

      if (response.ok) {
        if (data.needVerification) {
          setShowVerification(true);
          setVerificationEmail(email);
          Message.success(t('auth.messages.checkEmail'));
        }
      } else {
        const errorMessages = {
          'Email already registered': 'auth.errors.emailAlreadyRegistered',
          'Failed to send verification email': 'auth.errors.failedToSendEmail',
          'Invalid email': 'auth.errors.invalidEmail'
        };
        
        Message.error(t(errorMessages[data.error] || 'auth.errors.serverError'));
      }
    } catch (error) {
      Message.error(t('auth.errors.serverError'));
    } finally {
      setLoading(false);
    }
  };

  const handleVerify = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_BASE_URL}/api/auth/verify`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, code: verificationCode }),
      });
      
      const data = await response.json();
      
      if (!response.ok) {
        const errorMessages = {
          'Invalid or expired verification code': 'auth.errors.invalidOrExpiredCode'
        };
        
        throw new Error(t(errorMessages[data.error] || 'auth.errors.serverError'));
      }
      
      Message.success(t('auth.messages.emailVerified'));
      setActiveTab('login');
      setShowVerification(false);
    } catch (error) {
      Message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleResendVerification = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/auth/resend-verification`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
      });
      
      if (response.ok) {
        Message.success(t('auth.messages.codeSent'));
      } else {
        throw new Error(t('auth.errors.sendCodeFailed'));
      }
    } catch (error) {
      Message.error(error.message);
    }
  };

  const handleForgotPassword = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_BASE_URL}/api/auth/forgot-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });
      
      if (!response.ok) throw new Error(t('auth.errors.resetCodeFailed'));
      
      Message.success(t('auth.messages.resetCodeSent'));
      setResetStep(2);
    } catch (error) {
      Message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyResetCode = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_BASE_URL}/api/auth/verify-reset-code`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, code: resetCode }),
      });
      
      if (!response.ok) throw new Error(t('auth.errors.invalidResetCode'));
      
      setResetStep(3);
    } catch (error) {
      Message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleResetPassword = async () => {
    try {
      const passwordValidation = validatePassword(newPassword);
      if (!passwordValidation.valid) {
        Message.error(passwordValidation.message);
        return;
      }

      setLoading(true);
      const response = await fetch(`${API_BASE_URL}/api/auth/reset-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          email,
          code: resetCode,
          newPassword 
        }),
      });
      
      if (!response.ok) throw new Error(t('auth.errors.resetPasswordFailed'));
      
      Message.success(t('auth.messages.resetPasswordSuccess'));
      setShowForgotPassword(false);
      setActiveTab('login');
    } catch (error) {
      Message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const forgotPasswordButton = (
    <Button 
      type="text" 
      style={{ padding: 0, color: '#3491FA' }} 
      onClick={() => setShowForgotPassword(true)}
    >
      {t('auth.forgotPassword')}
    </Button>
  );

  const renderForgotPassword = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
        <h1 style={{
          textAlign: 'center',
          marginBottom: '30px',
          fontSize: '28px',
          fontWeight: 'bold',
          color: TITLE_COLOR
        }}>
          {t('auth.resetPassword')}
        </h1>
        
        {resetStep === 1 && (
          <>
            <Input
              placeholder={t('auth.email')}
              value={email}
              onChange={setEmail}
              style={{ height: '40px' }}
              autoComplete="username"
            />
            <Button 
              type="primary"
              loading={loading}
              onClick={handleForgotPassword}
              style={PRIMARY_BUTTON_STYLE}
            >
              {t('auth.sendResetCode')}
            </Button>
          </>
        )}

        {resetStep === 2 && (
          <>
            <Input
              placeholder={t('auth.verificationCode')}
              value={resetCode}
              onChange={setResetCode}
              style={{ height: '40px' }}
            />
            <Button 
              type="primary"
              loading={loading}
              onClick={handleVerifyResetCode}
              style={PRIMARY_BUTTON_STYLE}
            >
              {t('auth.verifyCode')}
            </Button>
          </>
        )}

        {resetStep === 3 && (
          <>
            <Input.Password
              placeholder={t('auth.newPassword')}
              value={newPassword}
              onChange={setNewPassword}
              style={{ height: '40px' }}
              autoComplete="new-password"
            />
            <Button 
              type="primary"
              loading={loading}
              onClick={handleResetPassword}
              style={PRIMARY_BUTTON_STYLE}
            >
              {t('auth.resetPassword')}
            </Button>
          </>
        )}

        <div style={{ textAlign: 'center', marginTop: '10px' }}>
          <Button 
            type="text" 
            style={{ color: '#3491FA' }} 
            onClick={() => {
              setShowForgotPassword(false);
              setActiveTab('login');
            }}
          >
            {t('auth.backToLogin')}
          </Button>
        </div>
      </div>
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (activeTab === 'login') {
      handleLogin();
    } else {
      handleRegister();
    }
  };

  return (
    <>
      {/* 背景遮罩 */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          background: 'rgba(255, 255, 255, 0.9)',
          backdropFilter: 'blur(4px)',
          zIndex: 1000,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        onClick={onClose}
      >
        {/* 登录卡片 */}
        <motion.div
          initial={{ opacity: 0, y: 20, scale: 0.95 }}
          animate={{ opacity: 1, y: 0, scale: 1 }}
          exit={{ opacity: 0, y: 20, scale: 0.95 }}
          transition={{ 
            duration: 0.3,
            ease: [0.4, 0, 0.2, 1]
          }}
          style={{
            width: '90%',
            maxWidth: '400px',
            zIndex: 1001,
          }}
          onClick={e => e.stopPropagation()}  // 防止点击卡片时触发背景点击
        >
          <div style={{
            background: 'white',
            borderRadius: '24px',
            padding: '40px',
            boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
            position: 'relative'
          }}>
            <Button
              type="text"
              icon={<IconClose />}
              style={{
                position: 'absolute',
                right: '16px',
                top: '16px',
                padding: '4px',
                minWidth: 'auto'
              }}
              onClick={onClose}
            />

            <h1 style={{
              textAlign: 'center',
              marginBottom: '30px',
              fontSize: '28px',
              fontWeight: 'bold',
              color: TITLE_COLOR
            }}>
              {showVerification ? t('auth.verifyEmail') : 
               (activeTab === 'login' ? t('auth.login') : t('auth.signup'))}
            </h1>

            {showVerification ? (
              <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                <Input
                  placeholder={t('auth.email')}
                  value={email}
                  disabled
                  autoComplete="username"
                />
                <Input
                  placeholder={t('auth.verificationCode')}
                  value={verificationCode}
                  onChange={setVerificationCode}
                />
                <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                  <Button 
                    type="primary" 
                    loading={loading} 
                    onClick={handleVerify} 
                    style={{ ...PRIMARY_BUTTON_STYLE, flex: 1 }}
                  >
                    {t('auth.verifyEmail')}
                  </Button>
                  <Button 
                    type="text" 
                    onClick={handleResendVerification}
                    style={{ color: '#3491FA' }}
                  >
                    {t('auth.resendCode')}
                  </Button>
                </div>
              </div>
            ) : showForgotPassword ? (
              renderForgotPassword()
            ) : (
              <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                <Input
                  placeholder={t('auth.email')}
                  value={email}
                  onChange={setEmail}
                  style={{ height: '40px' }}
                  autoComplete="username"
                />
                <Input.Password
                  placeholder={t('auth.password')}
                  value={password}
                  onChange={setPassword}
                  style={{ height: '40px' }}
                  autoComplete={activeTab === 'login' ? "current-password" : "new-password"}
                />
                {activeTab === 'login' && (
                  <div style={{ textAlign: 'right', marginTop: '-5px' }}>
                    {forgotPasswordButton}
                  </div>
                )}
                {activeTab === 'register' && (
                  <div style={{ marginTop: '10px' }}>
                    <Checkbox 
                      checked={agreedToTerms}
                      onChange={(checked) => setAgreedToTerms(checked)}
                    >
                      {t('auth.terms.agree')}{' '}
                      <Button 
                        type="text" 
                        style={TEXT_BUTTON_STYLE}
                        onClick={(e) => {
                          e.preventDefault();
                          window.open('/terms', '_blank');
                        }}
                      >
                        {t('auth.terms.link')}
                      </Button>
                      {' '}{t('auth.terms.and')}{' '}
                      <Button 
                        type="text" 
                        style={TEXT_BUTTON_STYLE}
                        onClick={(e) => {
                          e.preventDefault();
                          window.open('/privacy', '_blank');
                        }}
                      >
                        {t('auth.terms.privacy')}
                      </Button>
                    </Checkbox>
                  </div>
                )}
                {activeTab === 'register' && (
                  <>
                    <div style={{ position: 'relative' }}>
                      <Input
                        placeholder={t('invitation.placeholder')}
                        value={invitationCode}
                        onChange={setInvitationCode}
                        style={{ height: '40px' }}
                      />
                    </div>
                    <div style={{
                      fontSize: '13px',
                      color: '#86909C',
                      marginTop: '-8px',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '4px'
                    }}>
                      {t('auth.invitation.tooltip')}
                    </div>
                  </>
                )}
                <Button 
                  type="primary" 
                  htmlType="submit" 
                  loading={loading} 
                  style={{
                    ...PRIMARY_BUTTON_STYLE,
                    marginTop: '10px',
                  }}
                  disabled={activeTab === 'register' && !agreedToTerms}
                >
                  {activeTab === 'login' ? t('auth.login') : t('auth.signup')}
                </Button>
                <div style={{ textAlign: 'center', marginTop: '10px' }}>
                  {activeTab === 'login' ? (
                    <span>{t('auth.noAccount')}<Button type="text" style={{ color: '#3491FA' }} onClick={() => setActiveTab('register')}>{t('auth.signup')}</Button></span>
                  ) : (
                    <span>{t('auth.haveAccount')}<Button type="text" style={{ color: '#3491FA' }} onClick={() => setActiveTab('login')}>{t('auth.login')}</Button></span>
                  )}
                </div>
              </form>
            )}
          </div>
        </motion.div>
      </motion.div>
    </>
  );
};

export default Login;