import React, { useState, useEffect, useRef } from 'react';
import { Input, Button, Typography, Message, Skeleton, Progress } from '@arco-design/web-react';
import { IconUndo, IconAlignLeft, IconPaste, IconRight, IconCopy } from '@arco-design/web-react/icon';
import { TITLE_COLOR } from '../App';
import { useMediaQuery } from '@react-hook/media-query';
import { motion } from 'framer-motion';
import { useLanguage } from '../contexts/LanguageContext';
import { API_BASE_URL } from '../config';
import '../styles/scrollbar.css';

const { TextArea } = Input;
const { Paragraph } = Typography;

const AITextChecker = ({ 
  isLoggedIn, 
  detector, 
  setDetector, 
  onWordsUpdate, 
  remainingEnglishWords, 
  remainingChineseChars, 
  showLogin,
  onNewCheck 
}) => {
  const { t } = useLanguage();
  const [inputText, setInputText] = useState('');
  const [outputText, setOutputText] = useState('');
  const [loading, setLoading] = useState(false);
  const [showOutput, setShowOutput] = useState(false);
  const [isOutputComplete, setIsOutputComplete] = useState(false);
  const streamingRef = useRef(null);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [showLoginOverlay, setShowLoginOverlay] = useState(false);
  const [timeoutOccurred, setTimeoutOccurred] = useState(false);
  const [sessionId, setSessionId] = useState(Date.now());
  const currentSessionRef = useRef(null);

  const textAreaStyle = {
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: '10px',
    fontSize: 'clamp(14px, 3vw, 16px)',
    padding: '20px',
    paddingBottom: '80px',
    lineHeight: '1.6',
    resize: 'none',
  };

  const cnkiSampleText = "本研究的目的在于探索创新激励机制，以激发知识型员工潜能，提升企业的竞争力。通过深入了解知识型员工的特点和需求，结合现代管理理念和方法，构建一套科学、合理、有效的激励机制。具体而言，我们希望通过创新激励机制，为知识型员工提供更多的发展机会和空间满足他们的自我实现需求；同时，通过激励机制的优化，提高知识型员工的工作积极性和创新能力为企业创造更多的价值。例如，我们可以借鉴一些成功企业的经验。这些企业通过采用灵活的工作制度、丰富的培训资源以及多元化的激励方式，成功吸引和留住了大量的知识型人才，提升了企业的核心竞争力。";
  const turnitinSampleText = "Gliomas are the most common and aggressive type of primary brain tumors, arising from glial cells in the central nervous system (CNS). These tumors are classified by the World Health Organization (WHO) into grades I to IV, with glioblastoma multiforme (GBM) being the most malignant and deadly form. Despite advances in medical science, the prognosis for glioma patients remains poor, with median survival rates often less than two years for high-grade gliomas such as GBM.";

  const handleSampleClick = () => {
    setInputText(detector === 'turnitin' ? turnitinSampleText : cnkiSampleText);
  };

  const handlePasteText = async () => {
    try {
      const text = await navigator.clipboard.readText();
      const maxTokens = detector === 'turnitin' ? MAX_ENGLISH_WORDS : MAX_CHINESE_CHARS;
      
      // 如果是英文检测器
      if (detector === 'turnitin') {
        // 将文本分割成单词，保留所有标点符号和空格
        let currentLength = 0;
        let truncatedText = text;
        const words = text.match(/\b\w+\b/g) || [];
        
        if (words.length > maxTokens) {
          // 找到第 maxTokens 个单词在原文中的位置
          let count = 0;
          let lastIndex = 0;
          
          // 使用正则表达式匹配单词，同时记录位置
          const wordRegex = /\b\w+\b/g;
          let match;
          while ((match = wordRegex.exec(text)) !== null) {
            count++;
            if (count === maxTokens) {
              lastIndex = match.index + match[0].length;
              break;
            }
          }
          
          truncatedText = text.slice(0, lastIndex);
          setInputText(truncatedText);
          Message.warning(formatMessage(t('aiChecker.messages.maxEnglishWords'), { limit: MAX_ENGLISH_WORDS }));
        } else {
          setInputText(text);
        }
      } 
      // 如果是中文检测器
      else {
        if (text.length > maxTokens) {
          setInputText(text.slice(0, maxTokens));
          Message.warning(formatMessage(t('aiChecker.messages.maxChineseChars'), { limit: MAX_CHINESE_CHARS }));
        } else {
          setInputText(text);
        }
      }
    } catch (err) {
      Message.error(t('aiChecker.errors.clipboardAccess'));
    }
  };

  useEffect(() => {
    if (streamingRef.current) {
      streamingRef.current.scrollTop = streamingRef.current.scrollHeight;
    }
  }, [outputText]);

  const MAX_ENGLISH_WORDS = 500;
  const MAX_CHINESE_CHARS = 1000;

  const calculateTokens = (text, isEnglish) => {
    if (!text) return 0;
    const cleanText = text.trim();
    if (isEnglish) {
      // Split text into words using whitespace and punctuation
      const words = cleanText.match(/\b\w+\b/g) || [];
      return words.length;
    } else {
      // Chinese: 1 character = 1 token
      return cleanText.length;
    }
  };

  const getCurrentLimit = () => {
    return detector === 'turnitin' ? MAX_ENGLISH_WORDS : MAX_CHINESE_CHARS;
  };

  const getRemainingTokens = () => {
    return detector === 'turnitin' ? remainingEnglishWords : remainingChineseChars;
  };

  const calculateEstimatedTime = (wordCount) => {
    return Math.ceil(wordCount * 0.5);
  };

  const handleSubmit = async () => {
    if (!isLoggedIn) {
      setLoading(true);
      setShowOutput(true);
      setOutputText('');
      setIsOutputComplete(false);
      setShowSkeleton(true);
      setTimeoutOccurred(false);

      setTimeout(() => {
        setShowSkeleton(false);
        setOutputText(inputText);
        setIsOutputComplete(true);
        setShowLoginOverlay(true);
        setLoading(false);
        
        setTimeout(() => {
          showLogin();
        }, 1000);
        
      }, 2000);
      return;
    }

    if (!inputText.trim()) {
      Message.error(t('aiChecker.errors.emptyText'));
      return;
    }

    const inputTokens = calculateTokens(inputText, detector === 'turnitin');
    const currentLimit = getCurrentLimit();
    const remainingTokens = getRemainingTokens();

    if (inputTokens > currentLimit) {
      Message.error(
        formatMessage(
          detector === 'turnitin' 
            ? t('aiChecker.messages.maxEnglishWords')
            : t('aiChecker.messages.maxChineseChars'),
          { limit: detector === 'turnitin' ? MAX_ENGLISH_WORDS : MAX_CHINESE_CHARS }
        )
      );
      return;
    }

    if (remainingTokens < inputTokens) {
      const tokenType = detector === 'turnitin' 
        ? t('nav.englishTokens').toLowerCase()
        : t('nav.chineseTokens').toLowerCase();
        
      Message.error(
        formatMessage(t('aiChecker.errors.insufficientWords'), {
          required: inputTokens,
          remaining: remainingTokens,
          type: tokenType
        })
      );
      return;
    }

    setLoading(true);
    setShowOutput(true);
    setOutputText('');
    setIsOutputComplete(false);
    setShowSkeleton(true);
    setTimeoutOccurred(false);

    const thisSessionId = Date.now();
    currentSessionRef.current = thisSessionId;

    const endpoint = detector === 'turnitin' ? '/api/rewrite_turnitin' : '/api/rewrite';
    
    try {
      const timeoutId = setTimeout(() => {
        setTimeoutOccurred(true);
        Message.info({
          content: t('aiChecker.messages.checkHistory'),
          duration: 10000,
          closable: true
        });
      }, 15000);

      const response = await fetch(`${API_BASE_URL}${endpoint}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ text: inputText })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.error) {
        throw new Error(data.error);
      }

      clearTimeout(timeoutId);

      setShowSkeleton(false);
      setOutputText(data.result);
      setIsOutputComplete(true);
      setLoading(false);
      setTimeoutOccurred(false);

      // 更新剩余字数/词数
      if (data.remaining_english_words !== undefined) {
        onWordsUpdate('english', data.remaining_english_words);
      } else if (data.remaining_chinese_chars !== undefined) {
        onWordsUpdate('chinese', data.remaining_chinese_chars);
      }

      // 触发新的历史记录事件
      window.dispatchEvent(new CustomEvent('newHistoryAdded'));

    } catch (error) {
      setLoading(false);
      setShowSkeleton(false);
      Message.error(t('aiChecker.errors.requestFailed'));
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(outputText).then(() => {
      Message.success(t('aiChecker.messages.copied'));
    });
  };

  const handleUndo = () => {
    setInputText('');
    setOutputText('');
    setShowOutput(false);
    setIsOutputComplete(false);
    setShowSkeleton(false);
    setLoading(false);
    setSessionId(Date.now());
    currentSessionRef.current = null;
  };

  const isMobile = useMediaQuery('(max-width: 767px)');

  // 修改 formatMessage 函数，加默认值处理
  const formatMessage = (template, values) => {
    const defaultValues = {
      count: '0',  // 设置默认值为 '0'
      ...values
    };
    return template.replace(/\{(\w+)\}/g, (match, key) => defaultValues[key] || match);
  };

  // 修改输出框的容器和内容样式
  const outputContainerStyle = {
    height: '450px',
    backgroundColor: 'white',
    border: '1px solid #D9D9D9',
    borderRadius: '10px',
    position: 'relative',
    opacity: 1,
  };

  const outputContentStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: '70px',
    padding: '20px',
    overflowY: 'auto',
    fontSize: 'clamp(14px, 3vw, 16px)',
    lineHeight: '1.6',
  };

  useEffect(() => {
    const handleReset = () => {
      setInputText('');
      setOutputText('');
      setShowOutput(false);
      setIsOutputComplete(false);
      setShowSkeleton(false);
      setLoading(false);
    };

    window.addEventListener('resetAITextChecker', handleReset);
    return () => {
      window.removeEventListener('resetAITextChecker', handleReset);
    };
  }, []);

  // 修改 TextArea 的 onChange 处理函数
  const handleTextAreaChange = (value) => {
    const maxTokens = detector === 'turnitin' ? MAX_ENGLISH_WORDS : MAX_CHINESE_CHARS;
    
    if (detector === 'turnitin') {
      const words = value.match(/\b\w+\b/g) || [];
      if (words.length > maxTokens) {
        let count = 0;
        let lastIndex = 0;
        
        const wordRegex = /\b\w+\b/g;
        let match;
        while ((match = wordRegex.exec(value)) !== null) {
          count++;
          if (count === maxTokens) {
            lastIndex = match.index + match[0].length;
            break;
          }
        }
        
        setInputText(value.slice(0, lastIndex));
        Message.warning(formatMessage(t('aiChecker.messages.maxEnglishWords'), { limit: MAX_ENGLISH_WORDS }));
      } else {
        setInputText(value);
      }
    } else {
      if (value.length > maxTokens) {
        setInputText(value.slice(0, maxTokens));
        Message.warning(formatMessage(t('aiChecker.messages.maxChineseChars'), { limit: MAX_CHINESE_CHARS }));
      } else {
        setInputText(value);
      }
    }
  };

  // Add overlay styles
  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    display: showLoginOverlay ? 'flex' : 'none',
    backdropFilter: 'blur(8px)',
    WebkitBackdropFilter: 'blur(8px)',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '20px',
    padding: '20px',
    borderRadius: '10px',
    zIndex: 2,
  };

  // 添加对 isLoggedIn 的监听
  useEffect(() => {
    // 只在登录状态发生变化，且变为已登录状态时执行
    if (isLoggedIn) {
      // 重置输出相关的状态，但保留输入框内容
      setOutputText('');
      setShowOutput(false);
      setIsOutputComplete(false);
      setShowSkeleton(false);
      setLoading(false);
      setShowLoginOverlay(false);
    }
  }, [isLoggedIn]);

  // 添加对 onNewCheck 的监听
  useEffect(() => {
    const handleNewCheck = () => {
      handleUndo();
    };

    window.addEventListener('resetAITextChecker', handleNewCheck);
    return () => {
      window.removeEventListener('resetAITextChecker', handleNewCheck);
    };
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, y: -40 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ 
        duration: 0.8,
        delay: isLoggedIn ? 0 : 2.5,
        ease: [0.4, 0, 0.2, 1],
      }}
      style={{ 
        position: 'relative',
        maxWidth: '1600px',
        width: '100%',
        margin: '0 auto',
      }}
    >
      <div style={{
        display: 'grid',
        gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr',
        gap: isMobile ? '20px' : '30px',
        padding: isMobile ? '0' : '0 20px',
        width: '100%',
      }}>
        {/* 左侧输入区域 */}
        <div style={{
          background: 'rgba(255, 255, 255, 0.7)',
          borderRadius: '20px',
          padding: isMobile ? '10px' : '20px',
          boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
          margin: isMobile ? '0 10px' : '0',
        }}>
          <div style={{
            height: '450px',
            backgroundColor: 'white',
            border: '1px solid #D9D9D9',
            borderRadius: '10px',
            position: 'relative',
            opacity: 1,
          }}>
            <TextArea
              style={textAreaStyle}
              value={inputText}
              onChange={handleTextAreaChange}
              placeholder={t('aiChecker.placeholder')}
            />

            {/* Try Sample 和 Paste Text 按钮 */}
            {!inputText && (
              <div style={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                display: 'flex',
                gap: '10px',
              }}>
                <Button
                  onClick={handleSampleClick}
                  style={{
                    width: '100px',
                    height: '100px',
                    padding: '10px',
                    border: '1px solid rgba(229, 230, 232, 0.8)',
                    borderRadius: '12px',
                    color: '#86909C',
                    fontWeight: 'normal',
                    fontSize: 'clamp(12px, 2.5vw, 14px)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'rgba(247, 248, 250, 0.8)',
                    textAlign: 'center',
                    backdropFilter: 'blur(8px)',
                    transition: 'all 0.3s ease',
                    ':hover': {
                      transform: 'translateY(-2px)',
                      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)',
                      borderColor: '#3491FA',
                      color: '#3491FA',
                    }
                  }}
                >
                  <IconAlignLeft style={{ fontSize: '24px', marginBottom: '5px', marginRight: '5px' }} />
                  <span style={{ transform: 'translateX(-5px)' }}>
                    {t('aiChecker.buttons.sample')}
                  </span>
                </Button>
                <Button
                  onClick={handlePasteText}
                  style={{
                    width: '100px',
                    height: '100px',
                    padding: '10px',
                    border: '1px solid rgba(229, 230, 232, 0.8)',
                    borderRadius: '12px',
                    color: '#86909C',
                    fontWeight: 'normal',
                    fontSize: 'clamp(12px, 2.5vw, 14px)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'rgba(247, 248, 250, 0.8)',
                    textAlign: 'center',
                    backdropFilter: 'blur(8px)',
                    transition: 'all 0.3s ease',
                    ':hover': {
                      transform: 'translateY(-2px)',
                      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)',
                      borderColor: '#3491FA',
                      color: '#3491FA',
                    }
                  }}
                >
                  <IconPaste style={{ fontSize: '24px', marginBottom: '5px', marginRight: '5px' }} />
                  <span style={{ transform: 'translateX(-5px)' }}>
                    {t('aiChecker.buttons.paste')}
                  </span>
                </Button>
              </div>
            )}

            {/* 底部工具栏 */}
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '10px 20px',
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              height: '56px',
              background: 'white',
              borderBottomLeftRadius: '10px',
              borderBottomRightRadius: '10px',
              zIndex: 1,
            }}>
              {/* 字数统计 */}
              <div style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                fontSize: 'clamp(12px, 2.5vw, 14px)',
                color: '#86909C',
              }}>
                <span>
                  {detector === 'turnitin' 
                    ? formatMessage(t('aiChecker.messages.wordCount'), {
                        count: inputText ? calculateTokens(inputText, true) : '0',
                        limit: MAX_ENGLISH_WORDS
                      })
                    : formatMessage(t('aiChecker.messages.charCount'), {
                        count: inputText ? calculateTokens(inputText, false) : '0',
                        limit: MAX_CHINESE_CHARS
                      })
                  }
                </span>
              </div>

              {/* Start Rewriting/Try for Free 按钮 */}
              <Button 
                type="primary" 
                onClick={handleSubmit} 
                loading={loading} 
                style={{
                  height: '36px',
                  borderRadius: '8px',
                  background: '#3491FA',
                  border: 'none',
                  minWidth: '120px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px',
                  fontSize: '14px',
                  fontWeight: '600',
                  boxShadow: '0 4px 12px rgba(52, 145, 250, 0.25)',
                }}
              >
                {isLoggedIn ? t('aiChecker.buttons.startRewriting') : t('aiChecker.buttons.tryForFree')} <IconRight style={{ fontSize: '16px' }} />
              </Button>
            </div>
          </div>
        </div>

        {/* 右侧输出区域 */}
        <div style={{
          background: 'rgba(255, 255, 255, 0.7)',
          borderRadius: '20px',
          padding: isMobile ? '10px' : '20px',
          boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)' ,
          display: isMobile && !showOutput ? 'none' : 'block',
          margin: isMobile ? '0 10px' : '0',
        }}>
          <div style={outputContainerStyle}>
            <div 
              ref={streamingRef}
              className="custom-scrollbar"
              style={outputContentStyle}
            >
              {showSkeleton ? (
                <div style={{
                  opacity: isOutputComplete ? 0 : 1,
                  transition: 'opacity 1s ease-out',
                }}>
                  <Skeleton text={{ rows: 8 }} animation />
                </div>
              ) : (
                <div style={{
                  opacity: isOutputComplete ? 1 : 0,
                  transition: 'opacity 0.3s ease-in',
                }}>
                  <Paragraph 
                    style={{ 
                      fontSize: 'clamp(14px, 3vw, 16px)', 
                      whiteSpace: 'pre-wrap',
                      wordBreak: 'break-word',
                      overflowWrap: 'break-word'
                    }}
                  >
                    {outputText || t('aiChecker.messages.waiting')}
                  </Paragraph>
                </div>
              )}
            </div>

            {/* Add login overlay */}
            <div style={overlayStyle}>
              <div
                onClick={showLogin}
                style={{
                  fontSize: 'clamp(14px, 3vw, 16px)',
                  color: '#86909C',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  transition: 'color 0.3s ease',
                  ':hover': {
                    color: '#3491FA'
                  }
                }}
              >
                {t('aiChecker.loginPrompt')}
                <IconRight style={{ fontSize: '16px', color: '#3491FA' }} />
              </div>
            </div>

            {/* 添加输出框底部工具栏 */}
            <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              padding: '10px 20px',
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              height: '56px',
              background: 'white',
              borderBottomLeftRadius: '10px',
              borderBottomRightRadius: '10px',
              zIndex: 1,
            }}>
              {isOutputComplete && (
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '12px',
                }}>
                  <IconUndo
                    style={{ 
                      cursor: 'pointer',
                      fontSize: '20px',
                      color: '#86909C',
                      transition: 'color 0.3s ease',
                      ':hover': {
                        color: '#3491FA'
                      }
                    }}
                    onClick={handleUndo}
                  />
                  <IconCopy
                    style={{ 
                      cursor: 'pointer',
                      fontSize: '20px',
                      color: '#86909C',
                      transition: 'color 0.3s ease',
                      ':hover': {
                        color: '#3491FA'
                      }
                    }}
                    onClick={copyToClipboard}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default AITextChecker;
