export const translations = {
    en: {
      nav: {
        features: 'Features',
        pricing: 'Pricing',
        login: 'Login',
        signup: 'Sign Up',
        logout: 'Logout',
        tokens: 'Tokens',
        englishTokens: "English Words",
        chineseTokens: "Chinese Characters",
        remainingQuota: "Quota"
      },
      hero: {
        trusted: 'Trusted',
        bypass: 'Bypass AI Detection',
        tool: 'Tool',
        description: 'Trained on over 100,000 articles and validated through 1,000 real detection tests, our algorithms transform 100% AI-only content into 100% human-only content, successfully bypassing AI detection checks.',
        selectDetector: 'Select the detector you need to pass: '
      },
      aiChecker: {
        detector: 'Detector',
        detectors: {
          turnitin: 'Turnitin(English)',
          cnki: '知网(中文)'
        },
        steps: {
          understand: 'Understand Input',
          check: 'Check AI Content',
          compute: 'Start Computing',
          final: 'Final Check'
        },
        buttons: {
          startRewriting: 'Start Rewriting',
          tryForFree: 'Try for Free',
          paste: 'Paste Text',
          sample: 'Try Sample',
          copy: 'Copy',
          undo: 'Undo',
          getStarted: 'Get Started'
        },
        messages: {
          waiting: 'waiting...',
          maxLength: '1000',
          copied: 'Copied to clipboard',
          wordCount: '{count} / {limit} words',
          charCount: '{count} / {limit} chars',
          maxEnglishWords: 'Maximum {limit} words allowed per request',
          maxChineseChars: 'Maximum {limit} characters allowed per request',
          checkHistory: "Processing is taking longer than expected. Please check the writing history for results later.",
          processing: "Processing your text...",
          estimatedTime: "Estimated processing time: {seconds} seconds",
          unlockContent: 'Login to unlock the full content'
        },
        errors: {
          loginRequired: 'Please login to continue',
          emptyText: 'Please enter text',
          insufficientWords: 'Insufficient balance. You need {required} {type} but only have {remaining} remaining. Please purchase more.',
          clipboardAccess: 'Cannot access clipboard, please paste manually',
          checkoutFailed: 'Failed to create checkout session',
          requestFailed: "Request failed"
        },
        placeholder: 'Enter text detected as AI-generated...',
        detectorDescription: {
          turnitin: 'Your selected target detector is Turnitin',
          cnki: 'Your selected target detector is CNKI'
        },
        loginPrompt: 'Login to unlock rewritten content'
      },
      features: {
        title: 'Features',
        description: 'Experience our comprehensive AI text optimization solutions that help your content pass detection checks effortlessly.',
        detection: {
          title: '<span style="color: #3491FA">Pass</span> Every Test - Minimal Detection Risk',
          description: 'Our sophisticated algorithms have been extensively tested and continuously optimized to guarantee that even fully AI-generated text clears Turnitin and CNKI checks with ease.',
          comparison: {
            title1: 'Other Tools',
            title2: 'Ushallpass.ai'
          }
        },
        rewriting: {
          title: '<span style="color: #3491FA">Pass</span>-Perfect Rewriting That Flows Naturally',
          description: 'Unlike other tools that often introduce errors or overly complex language, our algorithms preserve the core meaning while enhancing readability. We maintain proper grammar, formatting, and academic writing standards, ensuring your text remains sophisticated and coherent.',
          comparison: {
            header: {
              other: 'Other Tools',
              ushallpass: 'Ushallpass.ai'
            },
            items: {
              grammar: {
                issue: 'Deliberately create grammar errors (common singular/plural errors, morphological errors)',
                solution: 'Maintain correct grammar, conform to academic writing standards'
              },
              format: {
                issue: 'Deliberately create formatting errors with spaces',
                solution: 'Maintain reasonable formatting and layout'
              },
              punctuation: {
                issue: 'Deliberately create punctuation errors',
                solution: 'Use appropriate punctuation marks'
              },
              capitalization: {
                issue: 'Deliberately create capitalization errors',
                solution: 'Maintain correct capitalization usage'
              }
            }
          },
          examples: {
            tool1: 'Tool 1',
            tool2: 'Tool 2',
            tool3: 'Tool 3'
          }
        }
      },
      pricing: {
        title: 'Pricing Plans',
        description: 'Unlike other tools that charge monthly subscriptions, we offer pay-per-use plans that better suit your actual needs.',
        bestDeal: 'BEST DEAL',
        wordUnit: 'words',
        charUnit: 'characters',
        pay: 'Pay',
        free: "FREE",
        errors: {
          checkoutFailed: 'Failed to create checkout session'
        },
        free_plan: {
          targetUser: "For All New Users",
          feature1: "500 {unit} free detection",
          feature2: "Standard support"
        },
        basic: {
          targetUser: "For Individual Users",
          feature1: "1000 {unit} detection",
          feature2: "Standard support"
        },
        pro: {
          targetUser: "For Professional Writers & Teams",
          feature1: "5000 {unit} detection",
          feature2: "Priority support"
        },
        enterprise: {
          targetUser: "For Enterprise & Institutions",
          feature1: "20000 {unit} detection",
          feature2: "Priority support"
        },
        turnitin: {
          free_plan: {
            feature1: "200 words free detection",
          },
          basic: {
            feature1: "500 words detection",
          },
          pro: {
            feature1: "5000 words detection",
          }
        },
        detector: {
          turnitin: 'Turnitin',
          cnki: 'CNKI'
        }
      },
      auth: {
        login: 'Log In',
        signup: 'Sign Up',
        email: 'E-Mail',
        password: 'Password',
        forgotPassword: 'Forgot password?',
        noAccount: 'Don\'t have an account?',
        hasAccount: 'Already have an account?',
        verifyEmail: 'Verify Email',
        verificationCode: 'Verification Code',
        resendCode: 'Resend Code',
        resetPassword: 'Reset Password',
        newPassword: 'New Password',
        backToLogin: 'Back to Login',
        sendResetCode: 'Send Reset Code',
        verifyCode: 'Verify Code',
        messages: {
          verificationRequired: 'Account not verified. Verification code has been sent to your email.',
          loginSuccess: 'Login successful',
          checkEmail: 'Please check your email for verification code',
          emailVerified: 'Email verified successfully. Please login.',
          codeSent: 'New verification code sent',
          passwordRequirements: 'Password must be at least 8 characters long and contain uppercase, lowercase letters and numbers',
          resetCodeSent: 'Reset code has been sent to your email',
          resetPasswordSuccess: 'Password reset successfully, please login'
        },
        errors: {
          sendCodeFailed: 'Failed to send verification code',
          resetCodeFailed: 'Failed to send reset code',
          invalidResetCode: 'Invalid reset code',
          resetPasswordFailed: 'Failed to reset password',
          invalidPassword: "Invalid password",
          accountNotExist: "Account does not exist",
          emailAlreadyRegistered: "Email already registered",
          failedToSendEmail: "Failed to send verification email",
          invalidOrExpiredCode: "Invalid or expired verification code",
          accountNotVerified: "Account not verified",
          userNotFound: "User not found",
          invalidEmail: "Invalid email address",
          serverError: "Server error, please try again later"
        },
        terms: {
          agree: 'I agree to the',
          link: 'Terms of Service',
          privacy: 'Privacy Policy',
          and: 'and',
          error: 'Please agree to the Terms of Service and Privacy Policy'
        },
        invitation: {
          tooltip: '💰 Enter invitation code to receive 200 English words & 500 Chinese characters bonus quota for both you and the inviter!'
        }
      },
      payment: {
        success: {
          title: 'Payment Successful!',
          subtitle: 'Your tokens have been added to your account.',
          backHome: 'Back to Home'
        }
      },
      terms: {
        lastUpdated: 'Last updated',
        sections: {
          introduction: {
            title: 'Introduction',
            content: 'Welcome to Ushallpass.ai! Our service provides advanced text optimization tools designed to enhance your content. By accessing and using our services, you agree to comply with and be bound by the following terms and conditions. Please review them carefully before using the services.'
          },
          eligibility: {
            title: 'Eligibility',
            content: 'You must be at least 18 years of age to use Ushallpass.ai, or possess legal parental or guardian consent if under the age of 18.'
          },
          serviceUsage: {
            title: 'Service Usage',
            content: [
              {
                title: 'License',
                content: 'We grant you a limited, non-exclusive, non-transferable, and revocable license to access and use our services strictly in accordance with these terms.'
              },
              {
                title: 'Content Responsibility',
                content: '<span style="font-weight: bold">Users must ensure all submitted content is from legitimate sources and complies with intellectual property regulations. The platform bears no legal responsibility for any processed content or results if users violate these terms.</span>'
              },
              {
                title: 'Usage Limitations',
                content: '<span style="font-weight: bold">All text provided by users shall be used solely for rewriting purposes. The platform disclaims all liability for any consequences or responsibilities arising from using the processed content for other purposes.</span>'
              },
              {
                title: 'Prohibited Use',
                content: 'The service must not be used for any illegal or unauthorized purposes. Specifically, you agree not to use the service to:',
                items: [
                  'Facilitate or promote illegal activities',
                  'Infringe upon the intellectual property rights of others or distribute copyright-infringing materials',
                  'Engage in deceptive or harmful practices'
                ]
              }
            ]
          },
          userResponsibilities: {
            title: 'User Responsibilities',
            items: [
              {
                title: 'Account Security',
                content: 'You are responsible for maintaining the confidentiality of your account login information and are liable for all activities that occur under your account.'
              },
              {
                title: 'Content Submission',
                content: 'You assume full responsibility for the accuracy of the text submitted and the output generated by our services.'
              },
              {
                title: 'Payments',
                content: 'Service fees must be paid to access enhanced features and services. You agree to ensure timely and complete payments using a valid payment method.'
              }
            ]
          },
          intellectualProperty: {
            title: 'Intellectual Property Rights',
            content: 'All content, including but not limited to the software, designs, text, images, and databases, generated by or connected with Ushallpass.ai, is protected by copyright and international intellectual property laws. You are strictly prohibited from copying, modifying, or distributing any content without express written permission from us.'
          },
          liability: {
            title: 'Limitation of Liability',
            content: 'Ushallpass.ai is not liable for any direct or indirect, incidental, consequential, special, or exemplary damages arising from your use or inability to use the services, including but not limited to damages for loss of profits, goodwill, use, data, or other intangible losses.'
          },
          amendments: {
            title: 'Amendments to Terms',
            content: 'We reserve the right to modify these terms at any time. Such modifications become effective immediately upon posting. Your continued use of our services following any such changes shall constitute your consent to such changes.'
          },
          contact: {
            title: 'Contact Information',
            support: {
              title: 'Technical Support',
              email: 'support@mail.ushallpass.ai',
              description: 'For account issues, payment problems, usage questions, and technical support. Our support team typically responds within 24 hours.'
            },
            feedback: {
              title: 'Business & Partnership',
              email: 'greyhame@mail.ushallpass.ai',
              description: 'For business cooperation, feature suggestions, and partnership inquiries.'
            }
          }
        }
      },
      privacy: {
        lastUpdated: 'Last Updated',
        sections: {
          introduction: {
            title: 'Privacy Policy',
            content: 'Welcome to UShallPass.ai. Your privacy is critically important to us. This Privacy Policy outlines the types of personal information we collect, how it is used, and the measures we take to protect it.'
          },
          informationCollection: {
            title: 'Information We Collect',
            content: [
              {
                title: 'User-Provided Information',
                content: 'We collect the text you provide for rewriting as well as your email address if you choose to subscribe to our newsletter.'
              },
              {
                title: 'Automated Information',
                content: 'We gather information like IP address, browser type, and usage statistics through standard analytics tools.'
              }
            ]
          },
          useOfInformation: {
            title: 'Use of Information',
            content: [
              {
                title: 'Service Provision',
                content: 'The text provided by you is used solely for the purpose of rewriting.'
              },
              {
                title: 'Communication',
                content: 'We may use your email to communicate service updates or promotional offers if you opt-in.'
              },
              {
                title: 'Improvement and Maintenance',
                content: 'Analytics data helps us enhance user experience and identify system needs.'
              }
            ]
          },
          dataSharing: {
            title: 'Data Sharing',
            content: 'We do not sell or rent your personal data to third parties. Data may be shared with service providers under strict confidentiality agreements for the purpose of operating our service, and only as legally required.'
          },
          dataSecurity: {
            title: 'Data Security',
            content: 'We implement a variety of security measures to maintain the safety of your personal information, including encryption and secure server configurations.'
          },
          userRights: {
            title: 'User Rights',
            content: 'You have the right to access, correct, or delete your personal data stored with us. Please contact us at',
            email: 'support@mail.ushallpass.ai'
          },
          changes: {
            title: 'Changes to Privacy Policy',
            content: 'We may modify this policy from time to time. All updates will be posted on this page with an updated revision date.'
          }
        }
      },
      footer: {
        terms: 'Terms of Service',
        privacy: 'Privacy Policy',
        contact: 'Contact Us'
      },
      writingHistory: {
        title: 'Writing History',
        input: 'Input',
        output: 'Output',
        words: 'Words',
        chars: 'Characters',
        empty: 'No writing history found'
      },
      contact: {
        support: {
          description: 'For general inquiries, technical support, and account-related issues.'
        },
        feedback: {
          description: 'For suggestions, feedback, and business cooperation.'
        }
      },
      invitation: {
        title: 'Invitation Code',
        generate: 'Generate Code',
        placeholder: 'Enter invitation code (optional)',
        yourCode: 'Your Invitation Code',
        copyCode: 'Copy Code',
        copied: 'Copied!',
        useRecords: 'Invitation Records',
        noRecords: 'No invitation records yet',
        reward: 'Successfully invite a friend to register, and both will receive: [200 English words] & [500 Chinese characters]. Stackable with no limit!',
        promotion: '🎉 2024 Launch Promotion (2024.12.10-2025.1.1) | Invite friends to register successfully, share rewriting quota, with no limit on accumulation!',
        errors: {
          generateFailed: 'Failed to generate invitation code',
          invalidCode: 'Invalid invitation code'
        },
        tooltip: 'Share your invitation code with friends',
        shareQuota: 'Share quota with friends',
        shareMessage: "Hi, I'm using Ushallpass.ai to bypass AI detection. Use my invitation code ",
        shareAction: " to get started: ",
        code: "My invitation code",
        link: "Quick registration link",
        benefits: "Register with my invitation code to get 200 English words & 500 Chinese characters bonus!",
        copyFailed: "Failed to copy invitation message"
      }
    },
    zh: {
      nav: {
        features: '功能特点',
        pricing: '价格方案',
        login: '登录',
        signup: '注册',
        logout: '退出登录',
        tokens: '剩余字数',
        englishTokens: "英文词数",
        chineseTokens: "中文字数",
        remainingQuota: "余量"
      },
      hero: {
        trusted: '值得信赖的',
        bypass: '降低AI检测率',
        tool: '工具',
        description: '基于超过10万篇文章的训练数据和1000多次真实检测结果，我们的对抗算法可以将100%AIGC生成文本痕迹消除，生产出类人文本同时保持学术写作质量，成功通过Turnitin和CNKI等AI检测。',
        selectDetector: '选择你需要通过的检测器'
      },
      aiChecker: {
        detector: '检测器',
        detectors: {
          turnitin: 'Turnitin(英文)',
          cnki: '知网(中文)'
        },
        steps: {
          understand: '理解输入',
          check: '检查AI痕迹',
          compute: '开始计算',
          final: '最终检查'
        },
        buttons: {
          startRewriting: '开始改写',
          tryForFree: '免费试用',
          paste: '粘贴文本',
          sample: '试用示例',
          copy: '复制',
          undo: '撤销',
          getStarted: '开始使用'
        },
        messages: {
          waiting: '等待中...',
          maxLength: '1000',
          copied: '已复制到剪贴板',
          wordCount: '{count} / {limit} 词',
          charCount: '{count} / {limit} 字',
          maxEnglishWords: '每次最多允许输入 {limit} 个英文单词',
          maxChineseChars: '每次最多允许输入 {limit} 个中文字符',
          checkHistory: "处理时间较长，请稍后在写作历史中查看结果。",
          processing: "正在处理您的文本...",
          estimatedTime: "预计处理时间：{seconds} 秒",
          unlockContent: '登录解锁完整内容'
        },
        errors: {
          loginRequired: '请先登录',
          emptyText: '请输入文本',
          insufficientWords: '余额不足。当前需要 {required} {type}，但您仅剩 {remaining} {type}。请购买更多额度。',
          clipboardAccess: '无法访问剪贴板，请手动粘贴文本',
          checkoutFailed: '创建支付会话失败',
          requestFailed: "请求失败"
        },
        placeholder: '请输入需要处理的AI生成文本...',
        detectorDescription: {
          turnitin: '你选择的目标检测器是 Turnitin',
          cnki: '你选择的目标检测器是知网'
        },
        loginPrompt: '登录以解锁改写内容'
      },
      features: {
        title: '功能特点',
        description: '体验我们全方位的AI文优化解决方案，帮助您的内容轻松通过检测。',
        detection: {
          title: '<span style="color: #3491FA">显著降低检测率</span>',
          description: '我们的算法经过广泛测试，采用先进的自然语言处理和模式分析技术，确保即使是完全由AI生成的文本也能被有效处理，节省你的时间。',
          comparison: {
            title1: '其他工具',
            title2: 'Ushallpass.ai'
          }
        },
        rewriting: {
          title: '<span style="color: #3491FA">保证原意和学术质量</span>',
          description: '与其他改写工具不同，我们不引入错误或过于复杂的语言。我们的算法在保持核心含义的同时提升可读性，确保语法正确、格式规范，符合学术写作标准，使文本保持连贯和专业性。',
          comparison: {
            header: {
              other: '其他工具',
              ushallpass: 'Ushallpass.ai'
            },
            items: {
              grammar: {
                issue: '故意制造语法错误（常见单复数错误、变形错误）',
                solution: '保持语法正确，符合学术写作规范'
              },
              format: {
                issue: '故意用空格制造格式错误',
                solution: '维持合理的格式和排版'
              },
              punctuation: {
                issue: '故意制造标点错误',
                solution: '使用恰当的标点符号'
              },
              capitalization: {
                issue: '故意制造大小写错误',
                solution: '保持正确的大小写用法'
              }
            }
          },
          examples: {
            tool1: '工具示例1',
            tool2: '工具示例2',
            tool3: '工具示例3'
          }
        }
      },
      pricing: {
        title: '价格方案',
        description: '不同于其他工具的月费订阅，我们提供更实用的按次付费方案，让您只为实际需求付费。',
        bestDeal: '最优选择',
        wordUnit: '词',
        charUnit: '字',
        pay: '支付',
        free: "免费",
        errors: {
          checkoutFailed: '创建支付会话失败'
        },
        free_plan: {
          targetUser: "所有新用户",
          feature1: "500{unit}免费检测",
          feature2: "标准客服支持"
        },
        basic: {
          targetUser: "适合个人用户日常使用",
          feature1: "1000{unit}检测",
          feature2: "标准客服支持"
        },
        pro: {
          targetUser: "适合专业写作者和团队",
          feature1: "5000{unit}检测",
          feature2: "优先客服支持"
        },
        enterprise: {
          targetUser: "适合企业机构",
          feature1: "20000{unit}检测",
          feature2: "优先客服支持"
        },
        turnitin: {
          free_plan: {
            feature1: "200词检测",
          },
          basic: {
            feature1: "500词检测",
          },
          pro: {
            feature1: "5000词检测",
          }
        },
        detector: {
          turnitin: 'Turnitin',
          cnki: '知网'
        }
      },
      auth: {
        login: '登录',
        signup: '注册',
        email: '邮箱',
        password: '密码',
        forgotPassword: '忘记密码？',
        noAccount: '没有账号？',
        hasAccount: '已有账号？',
        verifyEmail: '验证邮箱',
        verificationCode: '验证码',
        resendCode: '重新发送',
        resetPassword: '重置密码',
        newPassword: '新密码',
        backToLogin: '返回登录',
        sendResetCode: '发送重置码',
        verifyCode: '验证验证码',
        messages: {
          verificationRequired: '账号未验证。验证码已发送至您的邮箱。',
          loginSuccess: '登录成功',
          checkEmail: '请查看邮箱获取验证码',
          emailVerified: '邮箱验证成功，请登录。',
          codeSent: '新的验证码已发送',
          passwordRequirements: '密码必须包含至少8个字符，包含大小写字母和数字',
          resetCodeSent: '重置码已发送到您的邮箱',
          resetPasswordSuccess: '密码重置成功，请登录'
        },
        errors: {
          sendCodeFailed: '发送验证码失败',
          resetCodeFailed: '发送重置码失败',
          invalidResetCode: '无效的重置码',
          resetPasswordFailed: '重置密码失败',
          invalidPassword: "密码错误",
          accountNotExist: "账号不存在",
          emailAlreadyRegistered: "邮箱已被注册",
          failedToSendEmail: "发送验证邮件失败",
          invalidOrExpiredCode: "验证码无效或已过期",
          accountNotVerified: "账号未验证",
          userNotFound: "用户不存在",
          invalidEmail: "邮箱地址无效",
          serverError: "服务器错误，请稍后重试"
        },
        terms: {
          agree: '我同意',
          link: '服务条款',
          privacy: '隐私政策',
          and: '和',
          error: '请同意服务条款和隐私政策'
        },
        invitation: {
          tooltip: '💰 通过邀请码注册，将和发放者各获得新增英文200词和中文500字的额度奖励！'
        }
      },
      payment: {
        success: {
          title: '支付成功！',
          subtitle: '字数已添加到您的账户。',
          backHome: '返回首页'
        }
      },
      terms: {
        lastUpdated: '最后更新时间',
        sections: {
          introduction: {
            title: '引言',
            content: '欢迎使用Ushallpass.ai！我们的服务提供先进的文本优化工具。访问和使用我们的服务即表示您同意遵守并受以下条款和条件的约束。使用服务前，请仔细阅读这些条款。'
          },
          eligibility: {
            title: '适用对象',
            content: '您须至少达到18岁才能使用Ushallpass.ai，若未满18岁，则必须拥有法定父母或监护人的同意。'
          },
          serviceUsage: {
            title: '服务使用',
            content: [
              {
                title: '许可权',
                content: '我们授予您有限的、非排他性的、不可转让的、可撤销的许可，按照这些条款严格访问和使用我们的服务。'
              },
              {
                title: '内容责任',
                content: '<span style="font-weight: bold">用户必须确保所提交的内容来源合法且符合知识产权规定。如用户违反这些条款，平台对任何处理后的内容或结果所产生的法律责任概不负责。</span>'
              },
              {
                title: '使用限制',
                content: '<span style="font-weight: bold">用户提供的所有文本仅用于改写目的。如将处理后的内容用作其他用途，由此造成的一切后果及应承担的责任均与本平台无关。</span>'
              },
              {
                title: '禁止使用',
                content: '服务不得用于任何非法或未授权的目的。特别是，您同意将服务用于：',
                items: [
                  '促进或支持违法活动',
                  '侵犯他人的知识产权或分发侵权材料',
                  '从事欺诈或有害行为'
                ]
              }
            ]
          },
          userResponsibilities: {
            title: '用户责任',
            items: [
              {
                title: '账户安全',
                content: '您负责保持账户登录信息的机密性，并对您账户下发生的所有活动负责。'
              },
              {
                title: '内容提交',
                content: '您需对提交的文本及我们服务生成的输出承担全部责任。'
              },
              {
                title: '支付',
                content: '必须支付服务费用以访问增强功能和服务。您同意使用有效的支付方式确保及时、完整地支付。'
              }
            ]
          },
          intellectualProperty: {
            title: '知识产权',
            content: '所有内容，包括但不限于软件、设计、文字、图像和数据库，均受版权和国际知识产权法律保护未经我们明确的书面许可，严禁复制、修改或分发任何内容。'
          },
          liability: {
            title: '责任限制',
            content: 'Ushallpass.ai对由于使用或无法使用服务引起的任何直接或间接的偶然的、特殊的或示范性的损害均不承担责任，包括但不限于利润损失、商誉损失、数据损失或其他无形损失。'
          },
          amendments: {
            title: '条款修改',
            content: '我们保留随时修改这些条款的权利。一旦通过发布实施此类修改，则立即生效。您继续使用我们的服务即表示您接受此类更改。'
          },
          contact: {
            title: '联系方式',
            support: {
              title: '技术支持与客服',
              email: 'support@mail.ushallpass.ai',
              description: '处理账户问题、支付问题、使用咨询和技术支持。我们的支持团队通常会在24小时内回复。'
            },
            feedback: {
              title: '商务合作',
              email: 'greyhame@mail.ushallpass.ai',
              description: '用于商务合作、功能建议和合作咨询。'
            }
          }
        }
      },
      privacy: {
        lastUpdated: '最后更新时间',
        sections: {
          introduction: {
            title: '隐私政策',
            content: '欢迎来到UShallPass.ai。您的隐私对我们至关重要。本隐私政策概述了我们收集的个人信息类型、使用方式以及我们采取的保护措施。'
          },
          informationCollection: {
            title: '我们收集的信息',
            content: [
              {
                title: '用户提供信息',
                content: '我们收集您提供用于改写的文本以及您选择订阅时提供的电子邮件地址。'
              },
              {
                title: '自动化信息',
                content: '通过标准分析工具收集IP地址、浏览器类型和使用统计信息。'
              }
            ]
          },
          useOfInformation: {
            title: '信息的使用',
            content: [
              {
                title: '服务提供',
                content: '您提供的文本仅用于改写目的。'
              },
              {
                title: '沟通',
                content: '如果选择接受我们可能会使用的电子邮件与您通信，提供服务更新或促销优惠。'
              },
              {
                title: '改进和维护',
                content: '分析数据帮助我们提升用户体验并识别系统需求。'
              }
            ]
          },
          dataSharing: {
            title: '数据共享',
            content: '我们不会将您的个人数据出售或出租给第三方。据可能在严格保密协议的前提下与服务提供商共享，仅为了运营我们的服务，并且只在法律要求的情况下共享。'
          },
          dataSecurity: {
            title: '数据安全',
            content: '我们实施多种安全措施来保持您的个人信息安全，包括加密和安全服务器配置。'
          },
          userRights: {
            title: '用户权利',
            content: '您有权访问、更正或删除我们存储的您的个人资料。请通过',
            email: 'support@mail.ushallpass.ai'
          },
          changes: {
            title: '隐私政策变更',
            content: '我们可能会不时修改此政策。所有更新将在此页面上，并更新修订日期。'
          }
        }
      },
      footer: {
        terms: '服务条款',
        privacy: '隐私政策',
        contact: '联系我们'
      },
      writingHistory: {
        title: '写作历史',
        input: '输入',
        output: '输出',
        words: '词数',
        chars: '字数',
        empty: '没有找到写作历史'
      },
      contact: {
        support: {
          description: '用于一般咨询、技术支持和账户相关问题。'
        },
        feedback: {
          description: '用于建议、反馈和商务合作。'
        }
      },
      invitation: {
        title: '邀请码',
        generate: '生成邀请码',
        placeholder: '输入邀请码（选填）',
        yourCode: '你的邀请码',
        copyCode: '复制邀请码',
        copied: '已复制！',
        useRecords: '邀请记录',
        noRecords: '暂无邀请记录',
        reward: '每邀请一位好友注册成功，各获得 [英文200词] & [中文500字] 的改写额度。可累计叠加，无上限！',
        promotion: '🎉 2024上线促销（2024.12.10-2025.1.1） ｜ 邀请好友注册成功，每人共享改写额度，累计无上限！',
        errors: {
          generateFailed: '生成邀请码失败',
          invalidCode: '无效的邀请码'
        },
        tooltip: '分享邀请码给好友',
        shareQuota: '邀请好友共享quota',
        shareMessage: "Hi，我正在使用Ushallpass.ai通过AI检测，使用我的邀请码",
        shareAction: "开通体验：",
        code: "我的邀请码",
        link: "快速注册链接",
        benefits: "使用我的邀请码注册，即可获得200英文词和500中文字奖励！",
        copyFailed: "复制邀请信息失败"
      }
    }
  };