import React, { useState } from 'react';
import { Typography, Message, Card, Button, Tabs } from '@arco-design/web-react';
import { API_BASE_URL } from '../config';
import { useLanguage } from '../contexts/LanguageContext';

const { Title, Text } = Typography;
const TabPane = Tabs.TabPane;

const PriceCard = ({ price, originalPrice, amount, isTestMode, features, targetUser, onSelect, isFree, detector }) => {
  const { t } = useLanguage();
  
  const unit = detector === 'turnitin' ? t('pricing.wordUnit') : t('pricing.charUnit');
  
  return (
    <Card
      style={{
        width: 'calc(min(320px, 100% - 32px))',
        borderRadius: '12px',
        boxShadow: '0 4px 16px rgba(0, 0, 0, 0.05)',
        background: 'white',
        border: 'none',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        minHeight: '360px',
        padding: '24px',
      }}
      className="price-card"
    >
      <div style={{ marginBottom: 'auto' }}>
        <div style={{ 
          height: '24px', 
          marginBottom: '16px',
          display: 'flex',
          gap: '8px',
          alignItems: 'center'
        }}>
          <div style={{
            background: '#F2F3F5',
            padding: '4px 12px',
            borderRadius: '16px',
            display: 'inline-block',
            color: '#4E5969',
            fontSize: '13px',
            fontWeight: '500',
          }}>
            {t(`pricing.detector.${detector === 'turnitin' ? 'turnitin' : 'cnki'}`)}
          </div>
          {isTestMode && (
            <div style={{
              background: '#E8F3FF',
              padding: '4px 12px',
              borderRadius: '16px',
              display: 'inline-block',
              color: '#3491FA',
              fontSize: '13px',
              fontWeight: '500',
            }}>
              {t('pricing.bestDeal')}
            </div>
          )}
        </div>

        <Text style={{ 
          fontSize: '28px',
          fontWeight: '600',
          display: 'block',
          marginBottom: '8px',
          color: '#001A4D'
        }}>
          {amount} {unit}
        </Text>
        
        <div style={{ 
          height: 'auto',
          minHeight: '36px',
          marginBottom: '16px',
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: '4px'
        }}>
          {isFree ? (
            <Text style={{ 
              fontSize: '22px',
              color: '#3491FA',
              fontWeight: '600'
            }}>
              {t('pricing.free')}
            </Text>
          ) : (
            <>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                gap: '4px'
              }}>
                <Text style={{ 
                  fontSize: '14px',
                  color: '#86909C',
                  textDecoration: 'line-through',
                  marginRight: '8px'
                }}>
                  HKD ${originalPrice}
                </Text>
                <Text style={{ 
                  fontSize: '20px',
                  color: '#3491FA',
                  fontWeight: '600'
                }}>
                  HKD ${price}
                </Text>
              </div>
              <Text style={{
                fontSize: '13px',
                color: '#3491FA',
                fontWeight: '500',
                whiteSpace: 'nowrap'
              }}>
                ({Math.round((1 - price/originalPrice) * 100)}% OFF)
              </Text>
            </>
          )}
        </div>
        
        <Text style={{
          fontSize: '15px',
          color: '#4E5969',
          marginBottom: '16px',
          display: 'block',
          fontWeight: '500'
        }}>
          {targetUser}
        </Text>
        
        <ul style={{
          listStyle: 'none',
          padding: 0,
          margin: '0 0 24px 0'
        }}>
          {features.map((feature, index) => (
            <li key={index} style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '12px',
              color: '#4E5969',
              fontSize: '14px',
              fontWeight: '400'
            }}>
              <span style={{
                width: '24px',
                height: '24px',
                borderRadius: '50%',
                background: '#E8F3FF',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '14px',
                color: '#3491FA',
                marginRight: '12px',
                flexShrink: 0,
              }}>
                ✓
              </span>
              {feature}
            </li>
          ))}
        </ul>
      </div>
      
      {isFree ? (
        <div style={{ height: '44px' }}></div>
      ) : (
        <Button 
          type="primary" 
          long 
          onClick={onSelect}
          style={{
            height: '44px',
            fontSize: '16px',
            fontWeight: '500',
            borderRadius: '8px',
            background: '#3491FA',
            border: 'none'
          }}
        >
          {t('pricing.pay')}
        </Button>
      )}
    </Card>
  );
};

const Pricing = ({ onLoginRequired, detector }) => {
  const { t } = useLanguage();
  const token = localStorage.getItem('token');

  const unit = detector === 'turnitin' ? t('pricing.wordUnit') : t('pricing.charUnit');

  const cnkiPlans = [
    {
      id: "free_cnki",
      amount: 500,
      isFree: true,
      isTestMode: false,
      targetUser: t('pricing.free_plan.targetUser'),
      features: [
        t('pricing.free_plan.feature1').replace('{unit}', t('pricing.charUnit')),
        t('pricing.free_plan.feature2')
      ]
    },
    {
      id: "price_1QNHWLKBjaieGBZ5AQ7jXoUx",
      amount: 1000,
      price: 30,
      originalPrice: 40,
      isTestMode: false,
      targetUser: t('pricing.basic.targetUser'),
      features: [
        t('pricing.basic.feature1').replace('{unit}', t('pricing.charUnit')),
        t('pricing.basic.feature2')
      ]
    },
    {
      id: "price_1QNHeTKBjaieGBZ5LRoRooJr",
      amount: 5000,
      price: 100,
      originalPrice: 150,
      isTestMode: true,
      targetUser: t('pricing.pro.targetUser'),
      features: [
        t('pricing.pro.feature1').replace('{unit}', t('pricing.charUnit')),
        t('pricing.pro.feature2')
      ]
    }
  ];

  const turnitinPlans = [
    {
      id: "free_turnitin",
      amount: 200,
      isFree: true,
      isTestMode: false,
      targetUser: t('pricing.free_plan.targetUser'),
      features: [
        t('pricing.free_plan.feature1').replace('{unit}', t('pricing.wordUnit')),
        t('pricing.free_plan.feature2')
      ]
    },
    {
      id: "price_1QNHiFKBjaieGBZ5nRG8DsyU",
      amount: 500,
      price: 60,
      originalPrice: 75,
      isTestMode: false,
      targetUser: t('pricing.basic.targetUser'),
      features: [
        t('pricing.turnitin.basic.feature1').replace('{unit}', t('pricing.wordUnit')),
        t('pricing.basic.feature2')
      ]
    },
    {
      id: "price_1QNHkKKBjaieGBZ5WeNouAYA",
      amount: 5000,
      price: 400,
      originalPrice: 600,
      isTestMode: true,
      targetUser: t('pricing.pro.targetUser'),
      features: [
        t('pricing.turnitin.pro.feature1').replace('{unit}', t('pricing.wordUnit')),
        t('pricing.pro.feature2')
      ]
    }
  ];

  const handlePriceSelect = async (priceId) => {
    if (!token) {
      onLoginRequired?.();
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/api/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          priceId: priceId
        })
      });

      const data = await response.json();
      if (data.error) {
        throw new Error(data.error);
      }

      window.location.href = data.url;
    } catch (error) {
      Message.error(t('pricing.errors.checkoutFailed'));
      console.error(error);
    }
  };

  const plans = detector === 'turnitin' ? turnitinPlans : cnkiPlans;

  return (
    <div id="pricing" style={{ 
      width: '100%', 
      maxWidth: '1200px', 
      margin: '60px auto',
      padding: '0 16px',
      position: 'relative',
    }}>
      <Title 
        heading={2} 
        style={{ 
          textAlign: 'center', 
          marginBottom: '40px', 
          fontSize: '40px', 
          fontWeight: '600',
          background: 'linear-gradient(180deg, #001A4D 0%, #0A2D6E 100%)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          backgroundClip: 'text',
          color: 'transparent',
          wordBreak: 'keep-all',
        }}
      >
        {t('pricing.title')}
      </Title>

      <div style={{
        display: 'flex',
        justifyContent: 'center',
        gap: '24px',
        flexWrap: 'wrap',
        padding: '0 8px',
      }}>
        {plans.map((plan) => (
          <PriceCard
            key={plan.id}
            amount={plan.amount}
            price={plan.price}
            originalPrice={plan.originalPrice}
            isTestMode={plan.isTestMode}
            targetUser={plan.targetUser}
            features={plan.features}
            isFree={plan.isFree}
            onSelect={() => handlePriceSelect(plan.id)}
            detector={detector}
          />
        ))}
      </div>
    </div>
  );
};

export default Pricing;