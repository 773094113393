import React from 'react';
import { Typography } from '@arco-design/web-react';
import { useLanguage } from '../contexts/LanguageContext';
import { useMediaQuery } from '@react-hook/media-query';

const { Title, Paragraph } = Typography;

const Contact = () => {
  const { t } = useLanguage();
  const isMobile = useMediaQuery('(max-width: 768px)');
  
  const styles = {
    container: {
      maxWidth: '800px',
      margin: '40px auto',
      padding: '20px',
      minHeight: '100vh'
    },
    section: {
      marginBottom: '32px'
    },
    title: {
      marginBottom: '16px',
      fontWeight: 'bold'
    },
    content: {
      fontSize: '16px',
      lineHeight: '1.8',
      color: '#333',
      marginBottom: '16px',
      wordBreak: 'break-word'
    },
    emailContainer: {
      width: '800px',
      position: 'relative',
      left: '50%',
      transform: 'translateX(-50%)',
      display: 'flex',
      flexDirection: 'column',
      gap: '24px',
      padding: '24px',
      background: '#F7F8FA',
      borderRadius: '12px',
      marginTop: '24px',
      boxSizing: 'border-box'
    },
    emailSection: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '8px'
    },
    emailRow: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    },
    emailTitle: {
      fontSize: '16px',
      fontWeight: 500,
      color: '#1D2129',
      marginBottom: '8px'
    },
    emailLink: {
      display: 'inline-block',
      color: '#3491FA',
      textDecoration: 'none',
      fontSize: '16px',
      fontWeight: 500,
      wordBreak: 'break-all'
    },
    description: {
      fontSize: '14px',
      color: '#4E5969',
      marginTop: '4px'
    },
    divider: {
      width: '100%',
      height: '1px',
      background: '#E5E6EB',
      margin: '8px 0'
    }
  };

  const renderEmailSection = (type) => (
    <div style={styles.emailSection}>
      <div style={styles.emailRow}>
        <div style={styles.emailTitle}>
          {t(`terms.sections.contact.${type}.title`)}
        </div>
        <a 
          href={`mailto:${t(`terms.sections.contact.${type}.email`)}`}
          style={styles.emailLink}
        >
          {t(`terms.sections.contact.${type}.email`)}
        </a>
        <div style={styles.description}>
          {t(`terms.sections.contact.${type}.description`)}
        </div>
      </div>
    </div>
  );

  return (
    <div style={styles.container}>
      <Title 
        heading={2}
        style={{ 
          marginBottom: '24px',
          color: '#1D2129'
        }}
      >
        {t('terms.sections.contact.title')}
      </Title>
      
      <div style={styles.emailContainer}>
        {renderEmailSection('support')}
        <div style={styles.divider} />
        {renderEmailSection('feedback')}
      </div>
    </div>
  );
};

export default Contact; 