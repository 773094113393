import React from 'react';
import { Result, Button } from '@arco-design/web-react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../contexts/LanguageContext';

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const { t } = useLanguage();

  return (
    <div style={{
      width: '100%',
      maxWidth: '1200px',
      margin: '60px auto',
      padding: '0 20px',
    }}>
      <Result
        status="success"
        title={t('payment.success.title')}
        subTitle={t('payment.success.subtitle')}
        extra={[
          <Button
            key="home"
            type="primary"
            onClick={() => navigate('/')}
            style={{
              height: '44px',
              fontSize: '16px',
              borderRadius: '8px',
              background: '#3491FA',
              border: 'none'
            }}
          >
            {t('payment.success.backHome')}
          </Button>
        ]}
      />
    </div>
  );
};

export default PaymentSuccess; 