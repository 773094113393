import React, { useState, useEffect } from 'react';
import { Card, Button, Message, List, Typography, Spin } from '@arco-design/web-react';
import { IconCopy, IconClose } from '@arco-design/web-react/icon';
import { API_BASE_URL } from '../config';
import { useLanguage } from '../contexts/LanguageContext';
import '../styles/scrollbar.css';
import '../styles/animations.css';

const { Text } = Typography;

const InvitationCode = ({ onClose }) => {
  const { t } = useLanguage();
  const [invitationCode, setInvitationCode] = useState(null);
  const [useRecords, setUseRecords] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchInvitationCode = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/invitation/generate`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error(t('invitation.errors.generateFailed'));
      }

      const data = await response.json();
      setInvitationCode(data.code);
      setUseRecords(data.use_records || []);
    } catch (error) {
      Message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInvitationCode();
  }, []);

  const formatInvitationMessage = (code) => {
    const inviteLink = `${window.location.origin}/?invite=${code}&register=true`;
    return `${t('invitation.shareMessage')}"${code}"${t('invitation.shareAction')}

${inviteLink}`;
  };

  const handleCopyCode = () => {
    if (!invitationCode) return;
    
    const formattedMessage = formatInvitationMessage(invitationCode);
    navigator.clipboard.writeText(formattedMessage)
      .then(() => Message.success(t('invitation.copied')))
      .catch(() => Message.error(t('invitation.copyFailed')));
  };

  const validRecords = useRecords.filter(record => record.reward_status === true);

  return (
    <div style={{ 
      background: 'white',
      borderRadius: '20px',
    }}>
      <div style={{ 
        padding: '24px',
        background: 'linear-gradient(135deg, rgba(52, 161, 250, 1) 0%, rgba(28, 231, 131, 1) 100%)',
        borderRadius: '20px',
      }}>
        {/* 背景装饰 */}
        <div style={{
          position: 'absolute',
          top: '-50%',
          left: '-50%',
          width: '200%',
          height: '200%',
          background: 'radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 50%)',
          pointerEvents: 'none',
          animation: 'rotate 30s linear infinite',
        }} />

        {/* 关闭按钮 */}
        <div style={{ textAlign: 'right', marginBottom: '16px' }}>
          <IconClose 
            style={{ 
              fontSize: '20px',
              cursor: 'pointer',
              color: 'white',
              opacity: 0.8,
              transition: 'opacity 0.2s',
              ':hover': {
                opacity: 1
              }
            }} 
            onClick={onClose}
          />
        </div>

        {/* 标题 */}
        <div style={{ textAlign: 'center', marginBottom: '24px' }}>
          <Text style={{ 
            fontSize: '28px',
            fontWeight: '800',
            color: 'white',
            letterSpacing: '2px',
            position: 'relative',
            display: 'inline-block',
          }}>
            {t('invitation.yourCode')}
          </Text>
        </div>

        {/* 邀请码展示区域 */}
        <div style={{ 
          padding: '24px',
          marginBottom: '24px',
          background: 'rgba(255, 255, 255, 0.15)',
          backdropFilter: 'blur(10px)',
          borderRadius: '16px',
          transition: 'transform 0.3s ease',
          ':hover': {
            transform: 'translateY(-2px)'
          }
        }}>
          <div style={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: '12px',
            background: 'white',
            padding: '16px 20px',
            borderRadius: '12px',
            border: 'none',
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)',
            position: 'relative',
            overflow: 'hidden'
          }}>
            {/* 邀请码背景效果 */}
            <div style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: 'linear-gradient(45deg, rgba(52, 161, 250, 0.05) 0%, rgba(28, 231, 131, 0.05) 100%)',
              opacity: 0.5,
              pointerEvents: 'none',
            }} />

            <Text style={{ 
              flex: 1, 
              fontSize: '24px', 
              fontWeight: 'bold',
              background: 'linear-gradient(135deg, #3491FA, #1CE783)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              letterSpacing: '2px',
              textAlign: 'center',
              minHeight: '36px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
            }}>
              {loading ? <Spin style={{ margin: '0 8px' }} /> : invitationCode}
            </Text>
            <IconCopy 
              style={{
                fontSize: '20px',
                cursor: 'pointer',
                color: '#3491FA',
                position: 'relative',
                transition: 'all 0.3s ease',
                ':hover': {
                  transform: 'scale(1.1)',
                  color: '#1a7bfa'
                }
              }}
              onClick={handleCopyCode}
            />
          </div>
        </div>

        {/* 奖励说明文本 - 独立部分 */}
        <div style={{
          marginBottom: '24px',
          padding: '16px 20px',
          background: 'white',
          backdropFilter: 'blur(10px)',
          borderRadius: '12px',
          display: 'flex',
          alignItems: 'center',
          gap: '12px',
          position: 'relative',
          border: '1px dashed rgba(52, 161, 250, 0.5)',
          boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)',
          transition: 'transform 0.3s ease',
          ':hover': {
            transform: 'scale(1.02)'
          }
        }}>
          <span role="img" aria-label="trophy" style={{
            fontSize: '24px',
            color: '#FFD700',
            animation: 'shine 2s ease-in-out infinite'
          }}>
            🏆
          </span>
          <Text style={{ 
            fontSize: '14px',
            lineHeight: '1.8',
            color: '#4E5969',
            flex: 1,
          }}>
            {t('invitation.reward').split('[').map((part, i) => {
              if (i === 0) return part;
              const [num, text] = part.split(']');
              return (
                <React.Fragment key={i}>
                  <span style={{ 
                    fontWeight: 'bold',
                    color: '#3491FA',
                  }}>{num}</span>
                  {text}
                </React.Fragment>
              );
            })}
          </Text>
        </div>

        {/* 使用记录区域 */}
        <div style={{ marginTop: '24px' }}>
          <Text style={{ 
            fontSize: '16px',
            fontWeight: 500,
            color: 'white',
            display: 'block',
            marginBottom: '16px'
          }}>
            {t('invitation.useRecords')}
          </Text>

          {loading ? (
            <div style={{
              padding: '32px 0',
              textAlign: 'center',
            }}>
              <Spin />
            </div>
          ) : validRecords.length > 0 ? (
            <div style={{
              maxHeight: '300px',
              overflowY: 'auto',
              paddingRight: '10px',
              marginRight: '-10px',
              background: 'rgba(255, 255, 255, 0.15)',
              backdropFilter: 'blur(10px)',
              borderRadius: '12px',
              padding: '16px',
              transition: 'all 0.3s ease',
              ':hover': {
                background: 'rgba(255, 255, 255, 0.2)',
                transform: 'translateY(-1px)'
              }
            }}
            className="custom-scrollbar">
              <List
                bordered={false}
                style={{
                  backgroundColor: 'transparent'
                }}
                dataSource={validRecords}
                render={(item, index) => (
                  <List.Item
                    key={index}
                    style={{
                      padding: '12px 4px',
                      borderBottom: index === validRecords.length - 1 ? 'none' : '1px solid rgba(255, 255, 255, 0.2)',
                      background: 'transparent'
                    }}
                  >
                    <div style={{ width: '100%' }}>
                      <div style={{ 
                        display: 'flex', 
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: '12px'
                      }}>
                        <Text style={{ 
                          color: 'white',
                          fontWeight: 500,
                          flex: 1,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}>
                          {item.invited_user_id}
                        </Text>
                        <Text style={{ 
                          fontSize: '14px',
                          color: 'rgba(255, 255, 255, 0.85)',
                          flexShrink: 0
                        }}>
                          {new Date(item.used_at).toLocaleDateString()}
                        </Text>
                      </div>
                    </div>
                  </List.Item>
                )}
              />
            </div>
          ) : (
            <div style={{
              padding: '16px 0',
              textAlign: 'center',
            }}>
              <Text type="secondary" style={{ fontSize: '14px', color: 'white' }}>
                {t('invitation.noRecords')}
              </Text>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InvitationCode; 