import React from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../contexts/LanguageContext';
import { useMediaQuery } from '@react-hook/media-query';

const Footer = () => {
  const { t } = useLanguage();
  const isMobile = useMediaQuery('(max-width: 768px)');

  const footerLinks = [
    { text: t('footer.terms'), link: '/terms' },
    { text: t('footer.privacy'), link: '/privacy' },
    { text: t('footer.contact'), link: '/contact' }
  ];

  return (
    <footer style={{
      borderTop: '1px solid rgba(229, 230, 235, 0.75)',
      padding: isMobile ? '24px 16px' : '32px 0',
      background: 'white',
      marginTop: 'auto',
      width: '100%'
    }}>
      <div style={{
        maxWidth: '1200px',
        margin: '0 auto',
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: isMobile ? '20px' : '0'
      }}>
        <div style={{
          display: 'flex',
          alignItems: 'center'
        }}>
          <img 
            src='https://ushallpassmainland-1305187289.cos.accelerate.myqcloud.com/logo_tab.webp' 
            alt="Logo" 
            style={{ 
              height: '32px',
              marginRight: '24px'
            }} 
          />
          <div style={{
            display: 'flex',
            gap: isMobile ? '16px' : '24px'
          }}>
            {footerLinks.map((link, index) => (
              <Link 
                key={index} 
                to={link.link} 
                style={{ 
                  color: '#4E5969',
                  textDecoration: 'none',
                  fontSize: isMobile ? '14px' : '15px',
                  transition: 'color 0.2s ease',
                  ':hover': {
                    color: '#3491FA'
                  }
                }}
              >
                {link.text}
              </Link>
            ))}
          </div>
        </div>

        <div style={{ 
          color: '#86909C',
          fontSize: isMobile ? '12px' : '14px',
          display: 'flex',
          alignItems: 'center',
          gap: '8px'
        }}>
          <span>© {new Date().getFullYear()}</span>
          <span style={{
            fontWeight: 500,
            color: '#4E5969'
          }}>
            Ushallpass.ai
          </span>
          <span>All rights reserved</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;