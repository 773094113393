import React, { createContext, useState, useContext, useEffect } from 'react';
import { translations } from '../locales/translations';

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  // 获取浏览器语言并设置默认语言
  const getBrowserLanguage = () => {
    const browserLang = navigator.language || navigator.userLanguage;
    // 如果浏览器语言以 zh 开头 (如 zh-CN, zh-TW 等)返回 zh，否则返回 en
    return browserLang.toLowerCase().startsWith('zh') ? 'zh' : 'en';
  };

  // 使用浏览器语言作为初始值
  const [language, setLanguage] = useState(getBrowserLanguage());

  // 从 localStorage 读取已保存的语言设置
  useEffect(() => {
    const savedLanguage = localStorage.getItem('preferred-language');
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, []);

  // 当语言改变时保存到 localStorage
  useEffect(() => {
    localStorage.setItem('preferred-language', language);
  }, [language]);

  const t = (key) => {
    const keys = key.split('.');
    let value = translations[language];
    for (const k of keys) {
      value = value[k];
    }
    return value;
  };

  return (
    <LanguageContext.Provider value={{ language, setLanguage, t }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);