import React, { useState, useEffect } from 'react';
import { Drawer, Typography, List, Space, Button, Message } from '@arco-design/web-react';
import { IconCopy } from '@arco-design/web-react/icon';
import { API_BASE_URL } from '../config';
import { useLanguage } from '../contexts/LanguageContext';

const { Text, Title } = Typography;

const WritingHistory = ({ visible, onClose, history, loading, error }) => {
  const { t } = useLanguage();
  const [drawerWidth, setDrawerWidth] = useState(500);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setDrawerWidth('100%');
      } else {
        setDrawerWidth(500);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      Message.success(t('aiChecker.messages.copied'));
    } catch (err) {
      Message.error(t('aiChecker.errors.clipboardAccess'));
    }
  };

  const formatDateTime = (dateString) => {
    try {
      const date = new Date(dateString);
      
      if (isNaN(date.getTime())) {
        console.error('Invalid date:', dateString);
        return dateString;
      }
      
      const timezoneOffset = date.getTimezoneOffset();
      const localDate = new Date(date.getTime() - (timezoneOffset * 60000));
      
      const formattedDate = new Intl.DateTimeFormat(undefined, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
        timeZoneName: 'shortOffset'
      }).format(localDate);

      return formattedDate;
    } catch (error) {
      console.error('Error formatting date:', error);
      return dateString;
    }
  };

  const handleClose = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  return (
    <Drawer
      width={drawerWidth}
      title={t('writingHistory.title')}
      visible={visible}
      onCancel={handleClose}
      onClose={handleClose}
      footer={null}
      maskClosable={true}
      closable={true}
      escToExit={true}
      mask={true}
      mountOnEnter={true}
      unmountOnExit={true}
    >
      <List
        loading={loading}
        dataSource={history}
        noDataElement={
          <div style={{ 
            textAlign: 'center', 
            color: 'var(--color-text-3)',
            padding: '40px 0'
          }}>
            {error ? error : t('writingHistory.empty')}
          </div>
        }
        style={{
          border: 'none'
        }}
        render={(item, index) => (
          <List.Item
            key={index}
            style={{
              padding: window.innerWidth <= 768 ? '12px' : '16px',
              marginBottom: '16px',
              backgroundColor: 'var(--color-bg-2)',
              borderRadius: '8px',
              border: '1px solid var(--color-border)'
            }}
          >
            <Space direction="vertical" style={{ width: '100%' }}>
              <div style={{ 
                display: 'flex', 
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '8px',
                flexWrap: 'wrap',
                gap: '4px'
              }}>
                <Text type="secondary">
                  {formatDateTime(item.completed_at)}
                </Text>
                <Text>
                  {item.type === 'turnitin' ? 
                    `${t('writingHistory.words')}: ${item.words_used}` : 
                    `${t('writingHistory.chars')}: ${item.chars_used}`}
                </Text>
              </div>
              
              <div style={{ marginBottom: '8px' }}>
                <Title heading={6}>{t('writingHistory.input')}</Title>
                <div style={{ 
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  gap: '8px',
                  width: '100%'
                }}>
                  <Text style={{ 
                    flex: 1,
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word',
                    fontSize: window.innerWidth <= 768 ? '14px' : '16px'
                  }}>
                    {item.input_text}
                  </Text>
                  <Button
                    type="text"
                    icon={<IconCopy />}
                    onClick={() => copyToClipboard(item.input_text)}
                  />
                </div>
              </div>

              <div>
                <Title heading={6}>{t('writingHistory.output')}</Title>
                <div style={{ 
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  gap: '8px'
                }}>
                  <Text style={{ 
                    flex: 1,
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word',
                    fontSize: window.innerWidth <= 768 ? '14px' : '16px'
                  }}>
                    {item.output_text}
                  </Text>
                  <Button
                    type="text"
                    icon={<IconCopy />}
                    onClick={() => copyToClipboard(item.output_text)}
                  />
                </div>
              </div>
            </Space>
          </List.Item>
        )}
      />
    </Drawer>
  );
};

export default WritingHistory; 