import React, { useEffect, useRef } from "react";
import { createNoise3D } from "simplex-noise";

const WavyBackground = ({
  colors = [
    // "rgba(52, 145, 250, 0.3)",
    "rgba(52, 161, 250, 0.3)",
    "rgba(28, 231, 131, 0.2)",
    "rgba(52, 145, 250, 0.15)", // 更淡的主题蓝色
    "rgba(28, 231, 131, 0.1)",  // 更淡的主题绿色
  ],
  waveWidth = 50,
  backgroundFill = "#ffffff",
  blur = 10,
  speed = "fast",
  waveOpacity = 0.5,
  className,
}) => {
  const noise = createNoise3D();
  let w, h, nt, i, x, ctx, canvas;
  const canvasRef = useRef(null);

  const getSpeed = () => {
    switch (speed) {
      case "slow":
        return 0.001;
      case "fast":
        return 0.002;
      default:
        return 0.001;
    }
  };

  const init = () => {
    canvas = canvasRef.current;
    ctx = canvas.getContext("2d");
    w = ctx.canvas.width = window.innerWidth;
    h = ctx.canvas.height = window.innerHeight;
    ctx.filter = `blur(${blur}px)`;
    nt = 0;
    window.onresize = function () {
      w = ctx.canvas.width = window.innerWidth;
      h = ctx.canvas.height = window.innerHeight;
      ctx.filter = `blur(${blur}px)`;
    };
    render();
  };

  const drawWave = (n) => {
    nt += getSpeed();
    for (i = 0; i < n; i++) {
      ctx.beginPath();
      ctx.lineWidth = waveWidth;
      ctx.strokeStyle = colors[i % colors.length];
      for (x = 0; x < w; x += 5) {
        var y = noise(x / 800, 0.3 * i, nt) * 100;
        ctx.lineTo(x, y + h * 0.5);
      }
      ctx.stroke();
      ctx.closePath();
    }
  };

  const createGradientBackground = () => {
    const gradient = ctx.createLinearGradient(0, 0, 0, h);
    gradient.addColorStop(0, '#ffffff');     // 顶部白色
    gradient.addColorStop(0.7, '#ffffff');   // 70%处还是白色
    gradient.addColorStop(1, '#E8F3FF');     // 底部渐变到浅蓝色
    return gradient;
  };

  let animationId;
  const render = () => {
    // 先绘制渐变背景
    ctx.globalAlpha = 1;
    ctx.fillStyle = createGradientBackground();
    ctx.fillRect(0, 0, w, h);
    
    // 然后绘制波浪
    ctx.globalAlpha = waveOpacity;
    drawWave(5);
    
    animationId = requestAnimationFrame(render);
  };

  useEffect(() => {
    init();
    return () => {
      cancelAnimationFrame(animationId);
    };
  }, []);

  return (
    <canvas
      className={className}
      ref={canvasRef}
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 1,
      }}
    />
  );
};

export default WavyBackground; 