import React, { useState, useEffect, useCallback } from 'react';
import { ConfigProvider, Typography, Button, Dropdown, Menu, Divider, Tabs, Tooltip, Badge, Popover, Modal } from '@arco-design/web-react';
import { motion, AnimatePresence } from 'framer-motion';
import AITextChecker from './components/AITextChecker';
import Features from './components/Features';
import Pricing from './components/Pricing';
import '@arco-design/web-react/dist/css/arco.css';
import Login from './components/Login';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { IconMenu, IconHistory, IconThunderbolt, IconPlus, IconUserAdd, IconClose } from '@arco-design/web-react/icon';
import { useMediaQuery } from '@react-hook/media-query';
import { API_BASE_URL } from './config';
import PaymentSuccess from './components/PaymentSuccess';
import { useLanguage } from './contexts/LanguageContext';
import Terms from './components/Terms';
import Privacy from './components/Privacy';
import Footer from './components/Footer';
import WavyBackground from './components/WavyBackground';
import WritingHistory from './components/WritingHistory';
import Contact from './components/Contact';
import InvitationCode from './components/InvitationCode';

const { Title, Paragraph } = Typography;

export const TITLE_COLOR = '#001A4D';

const typingContainer = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.035,
      delayChildren: 1
    }
  }
};

const gradientAnimation = {
  animate: {
    background: [
      'linear-gradient(45deg, rgba(255,255,255,1) 20%, rgba(52,145,250,0.2) 50%, rgba(200,255,240,0.3) 100%)',
      'linear-gradient(45deg, rgba(200,255,240,0.3) 0%, rgba(255,255,255,1) 50%, rgba(52,145,250,0.2) 100%)',
      'linear-gradient(45deg, rgba(52,145,250,0.2) 0%, rgba(200,255,240,0.3) 50%, rgba(255,255,255,1) 100%)',
    ],
    transition: {
      duration: 10,
      repeat: Infinity,
      repeatType: "reverse",
      ease: "linear"
    }
  }
};

// 创建渐变背景组件
const GradientBackground = () => {
  return (
    <motion.div
      variants={gradientAnimation}
      animate="animate"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 0,
        pointerEvents: 'none',
      }}
    />
  );
};


const typingCharacter = {
  hidden: { opacity: 0, y: 0 },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.1
    }
  }
};

const scrollToElement = (elementId) => (e) => {
  e.preventDefault();
  if (window.location.pathname !== '/') {
    window.location.href = '/#' + elementId;
    return;
  }
  const element = document.getElementById(elementId);
  if (element) {
    element.scrollIntoView({ 
      behavior: 'smooth', 
      block: 'start'
    });
  }
};

const LanguageSwitch = () => {
  const { language, setLanguage } = useLanguage();
  
  return (
    <div
      onClick={() => setLanguage(language === 'zh' ? 'en' : 'zh')}
      style={{
        cursor: 'pointer',
        backgroundColor: 'white',
        // border: '1px solid #E5E6E8',
        borderRadius: '20px',
        padding: '6px 16px',
        fontSize: '14px',
        color: '#4E5969',
        userSelect: 'none',
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        justifyContent: 'center'
      }}
    >
      <img 
        src="https://ushallpassmainland-1305187289.cos.accelerate.myqcloud.com/global.webp" 
        alt="language" 
        style={{ 
          width: '16px', 
          height: '16px' 
        }} 
      />
      {language === 'zh' ? '中' : 'EN'}
    </div>
  );
};

const DetectorTabs = ({ activeTab, onChange }) => {
  return (
    <div style={{
      display: 'flex',
      background: 'rgba(255, 255, 255, 0.8)',
      backdropFilter: 'blur(10px)',
      padding: '3px',
      borderRadius: '16px',
      boxShadow: '0 4px 16px rgba(0, 0, 0, 0.1)',
      border: '1px solid rgba(255, 255, 255, 0.8)',
      transform: 'scale(0.95)',
    }}>
      {[
        { 
          key: 'turnitin', 
          logo: 'https://ushallpassmainland-1305187289.cos.accelerate.myqcloud.com/turnitin_logo.webp',
          width: '120px',
          height: '28px'
        },
        { 
          key: 'cnki', 
          logo: 'https://ushallpassmainland-1305187289.cos.accelerate.myqcloud.com/cnki_logo.webp',
          width: '120px',
          height: '28px'
        }
      ].map(tab => (
        <div
          key={tab.key}
          onClick={() => onChange(tab.key)}
          style={{
            padding: '8px 24px',
            borderRadius: '16px',
            cursor: 'pointer',
            border: '2px solid',
            borderColor: activeTab === tab.key ? '#3491FA' : 'transparent',
            userSelect: 'none',
            marginRight: '4px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          <img 
            src={tab.logo} 
            alt={tab.key}
            style={{
              width: tab.width,
              height: tab.height,
              objectFit: 'contain',
            }}
          />
        </div>
      ))}
    </div>
  );
};

const FloatingButtons = ({ isLoggedIn, remainingEnglishWords, remainingChineseChars, setHistoryVisible, onNewCheck }) => {
  const { t } = useLanguage();
  const [quotaVisible, setQuotaVisible] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [invitationVisible, setInvitationVisible] = useState(false);

  // 添加获取未读数量的函数
  const fetchUnreadCount = useCallback(async () => {
    if (!isLoggedIn) return;
    try {
      const response = await fetch(`${API_BASE_URL}/api/writing-history/unread-count`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        }
      });
      if (response.ok) {
        const data = await response.json();
        setUnreadCount(data.unread_count);
      }
    } catch (error) {
      console.error('Error fetching unread count:', error);
    }
  }, [isLoggedIn]);

  // 定期检查未读数量
  useEffect(() => {
    fetchUnreadCount();
    const interval = setInterval(fetchUnreadCount, 30000); // 每30秒检查一次
    return () => clearInterval(interval);
  }, [fetchUnreadCount]);

  // 修改历史按钮的点击处理
  const handleHistoryClick = async () => {
    setHistoryVisible(true);
    // 标记所有历史记录为已读
    try {
      await fetch(`${API_BASE_URL}/api/writing-history/mark-as-read`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        }
      });
      setUnreadCount(0);
    } catch (error) {
      console.error('Error marking history as read:', error);
    }
  };

  // 添加监听新历史记录的事件
  useEffect(() => {
    const handleNewHistory = () => {
      fetchUnreadCount();
    };
    window.addEventListener('newHistoryAdded', handleNewHistory);
    return () => {
      window.removeEventListener('newHistoryAdded', handleNewHistory);
    };
  }, [fetchUnreadCount]);

  if (!isLoggedIn) return null;
  
  return (
    <>
      <div style={{
        position: 'fixed',
        right: '20px',
        top: '120px',
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        zIndex: 100,
      }}>
        <Popover
          position='left'
          triggerType='click'
          content={
            <div style={{
              padding: '8px 16px',
              minWidth: '200px',
              background: 'white',
              borderRadius: '8px',
            }}>
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '4px 0',
                color: '#4E5969',
                fontSize: '14px',
              }}>
                <span style={{ color: '#1D2129' }}>{t('nav.englishTokens')}</span>
                <span style={{ color: '#3491FA', fontWeight: 500 }}>{remainingEnglishWords}</span>
              </div>
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '4px 0',
                color: '#4E5969',
                fontSize: '14px',
              }}>
                <span style={{ color: '#1D2129' }}>{t('nav.chineseTokens')}</span>
                <span style={{ color: '#3491FA', fontWeight: 500 }}>{remainingChineseChars}</span>
              </div>
            </div>
          }
          visible={quotaVisible}
          onVisibleChange={setQuotaVisible}
        >
          <Button
            type="text"
            onClick={() => setQuotaVisible(!quotaVisible)}
            style={{
              width: '40px',
              height: '40px',
              borderRadius: '50%',
              padding: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: 'white',
              boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
            }}
          >
            <IconThunderbolt style={{ fontSize: '20px', color: '#3491FA' }} />
          </Button>
        </Popover>

        <div style={{ position: 'relative' }}>
          <Button
            type="text"
            onClick={handleHistoryClick}
            style={{
              width: '40px',
              height: '40px',
              borderRadius: '50%',
              padding: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: 'white',
              boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
            }}
          >
            <IconHistory style={{ fontSize: '20px', color: '#3491FA' }} />
          </Button>
          {unreadCount > 0 && (
            <div style={{
              position: 'absolute',
              top: '-6px',
              right: '-6px',
              minWidth: '16px',
              height: '16px',
              padding: '0 4px',
              background: '#F53F3F',
              borderRadius: '8px',
              color: 'white',
              fontSize: '12px',
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              boxShadow: '0 2px 4px rgba(255, 87, 34, 0.3)',
              zIndex: 1,
            }}>
              {unreadCount}
            </div>
          )}
        </div>

        <Button
          type="text"
          onClick={onNewCheck}
          style={{
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            padding: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'white',
            boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
          }}
        >
          <IconPlus style={{ fontSize: '18px', color: '#3491FA' }} />
        </Button>
      </div>
    </>
  );
};

const GoldenInviteBox = ({ onClick, setLoginVisible, isLoggedIn }) => {
  const { t } = useLanguage();
  
  const handleClick = () => {
    if (isLoggedIn) {
      onClick();
    } else {
      setLoginVisible(true);
    }
  };
  
  return (
    <div
      onClick={handleClick}
      style={{
        width: '100%',
        height: '40px',
        background: 'linear-gradient(135deg, rgba(52, 161, 250),rgba(28, 231, 131))',
        cursor: 'pointer',
        position: 'relative',
        overflow: 'hidden',
        zIndex: 999,
        boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
      }}
    >
      <div
        style={{
          maxWidth: '1200px',
          margin: '0 auto',
          padding: '0 20px',
          height: '100%',
          color: 'white',
          fontWeight: 'bold',
          fontSize: '14px',
          textAlign: 'center',
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {t('invitation.promotion')}
      </div>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: '-100%',
          width: '50%',
          height: '100%',
          background: 'linear-gradient(90deg, transparent, rgba(255,255,255,0.4), transparent)',
          animation: 'shine 2s infinite',
          zIndex: 0,
        }}
      />
      <style>
        {`
          @keyframes shine {
            0% { left: -100%; }
            100% { left: 200%; }
          }
        `}
      </style>
    </div>
  );
};

const App = () => {
  const [remainingEnglishWords, setRemainingEnglishWords] = useState(0);
  const [remainingChineseChars, setRemainingChineseChars] = useState(0);
  const [loginVisible, setLoginVisible] = useState(false);
  const [userEmail, setUserEmail] = useState(localStorage.getItem('userEmail') || '');
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  const { t } = useLanguage();
  const [detector, setDetector] = useState('turnitin');
  const [historyVisible, setHistoryVisible] = useState(false);
  const [writingHistory, setWritingHistory] = useState([]);
  const [historyLoading, setHistoryLoading] = useState(false);
  const [historyError, setHistoryError] = useState(null);
  const [invitationVisible, setInvitationVisible] = useState(false);

  const fetchRemainingWords = useCallback(async () => {
    if (isLoggedIn) {
      const token = localStorage.getItem('token');
      if (!token) {
        setIsLoggedIn(false);
        return;
      }
      
      try {
        const response = await fetch(`${API_BASE_URL}/api/user/word-count`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        
        if (response.status === 401) {
          // Token is invalid or expired
          localStorage.removeItem('token');
          setIsLoggedIn(false);
          return;
        }
        
        if (response.ok) {
          const data = await response.json();
          setRemainingEnglishWords(data.remaining_english_words);
          setRemainingChineseChars(data.remaining_chinese_chars);
        } else {
          throw new Error('Failed to fetch word count');
        }
      } catch (error) {
        console.error('Error fetching remaining words:', error);
      }
    }
  }, [isLoggedIn]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const email = localStorage.getItem('userEmail');
    
    if (token) {
      setIsLoggedIn(true);
      setUserEmail(email || '');
      fetchRemainingWords();
    } else {
      setIsLoggedIn(false);
      setUserEmail('');
    }
  }, [fetchRemainingWords]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userEmail');
    setIsLoggedIn(false);
    setUserEmail('');
    window.location.href = '/'; // 退出后重定向到首页
  };

  // 移动端菜单组件
  const MobileMenu = () => (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      style={{
        position: 'absolute',
        top: '56px',
        left: 0,
        right: 0,
        background: 'white',
        padding: '10px',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        zIndex: 999,
      }}
    >
      <Menu style={{ border: 'none' }}>
        <Menu.Item key="features" onClick={(e) => {
          scrollToElement('features')(e);
          setMobileMenuVisible(false);
        }}>
          Features
        </Menu.Item>
        <Menu.Item key="pricing" onClick={(e) => {
          scrollToElement('pricing')(e);
          setMobileMenuVisible(false);
        }}>
          Pricing
        </Menu.Item>
      </Menu>
    </motion.div>
  );

  const handleLoginRequired = useCallback(() => {
    setLoginVisible(true);
  }, []);

  useEffect(() => {
    // 处理 URL hash 变化
    const handleHashChange = () => {
      const hash = window.location.hash.slice(1);
      if (hash) {
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
    };

    // 监听 hash 变化
    window.addEventListener('hashchange', handleHashChange);
    // 初始加载时也检查
    handleHashChange();

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  const fetchWritingHistory = useCallback(async () => {
    try {
      setHistoryLoading(true);
      setHistoryError(null);
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No authentication token found');
      }

      const response = await fetch(`${API_BASE_URL}/api/writing-history`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 401) {
        localStorage.removeItem('token');
        setIsLoggedIn(false);
        throw new Error('Authentication token expired');
      }

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      if (result.status === 'success' && result.data) {
        setWritingHistory(result.data);
      } else {
        throw new Error(result.error || 'Failed to fetch history');
      }
    } catch (error) {
      console.error('Error fetching history:', error);
      setHistoryError(error.message);
    } finally {
      setHistoryLoading(false);
    }
  }, [setIsLoggedIn]);

  // 在历史抽屉打开时获取数据
  const handleHistoryOpen = useCallback(() => {
    setHistoryVisible(true);
    fetchWritingHistory();
  }, [fetchWritingHistory]);

  const handleNewCheck = useCallback(() => {
    // 如果不在首页，先跳转到首页
    if (window.location.pathname !== '/') {
      window.location.href = '/';
      return;
    }

    // 滚动到顶部
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

    // 触发重置事件
    window.dispatchEvent(new CustomEvent('resetAITextChecker'));
  }, []);

  // 在 invitationVisible 状态变化时控制背景滚动
  useEffect(() => {
    if (invitationVisible) {
      // 禁用背景滚动
      document.body.style.overflow = 'hidden';
    } else {
      // 恢复背景滚动
      document.body.style.overflow = 'unset';
    }

    // 组件卸载时恢复滚动
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [invitationVisible]);

  // Add useEffect to control background scrolling for login modal
  useEffect(() => {
    if (loginVisible) {
      // Disable background scrolling
      document.body.style.overflow = 'hidden';
    } else {
      // Re-enable background scrolling
      document.body.style.overflow = 'unset';
    }

    // Cleanup function to ensure scrolling is re-enabled when component unmounts
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [loginVisible]);

  // 添加处理 URL 参数的 useEffect
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const inviteParam = urlParams.get('invite');
    const shouldRegister = urlParams.get('register');
    
    if (inviteParam && shouldRegister === 'true') {
      // 如果用户已登录，则不显示注册弹窗
      if (!isLoggedIn) {
        // 添加3秒延迟
        setTimeout(() => {
          setLoginVisible(true);
          // 将邀请码传递给 Login 组件
          localStorage.setItem('pendingInviteCode', inviteParam);
        }, 3500);
      }
      
      // 清除 URL 参数但不刷新页面
      const newUrl = window.location.pathname;
      window.history.pushState({}, '', newUrl);
    }
  }, [isLoggedIn]);

  return (
    <Router>
      <ConfigProvider>
        <div style={{ 
          margin: 0, 
          padding: 0, 
          boxSizing: 'border-box',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          width: '100%',  // 确保容器占满个宽
          overflowX: 'hidden', // 防止水平滚动
        }}>
          {/* <GradientBackground /> */}
          <nav style={{
            background: 'rgba(255, 255, 255, 0.9)',
            padding: isMobile ? '10px 15px' : '10px 40px',
            height: '56px',
            position: 'sticky',
            top: 0,
            zIndex: 1000,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              gap: '20px',
            }}>
              <div style={{ flexShrink: 0 }}>
                <a href="/" style={{ display: 'block' }}>
                  <img 
                    src='https://ushallpassmainland-1305187289.cos.accelerate.myqcloud.com/logo_tab.webp' 
                    alt="Logo" 
                    style={{ height: '40px' }} 
                  />
                </a>
              </div>
              
              {!isMobile && (
                <ul style={{
                  listStyle: 'none',
                  display: 'flex',
                  margin: 0,
                  padding: 0,
                  marginLeft: '30px', 
                }}>
                  <li style={{ marginRight: '40px' }}>
                    <a href="#features" onClick={scrollToElement('features')} style={{ 
                      textDecoration: 'none', 
                      color: '#001A4D',
                      fontSize: '16px'
                    }}>
                      {t('nav.features')}
                    </a>
                  </li>
                  <li>
                    <a href="#pricing" onClick={scrollToElement('pricing')} style={{ 
                      textDecoration: 'none', 
                      color: '#001A4D',
                      fontSize: '16px'
                    }}>
                      {t('nav.pricing')}
                    </a>
                  </li>
                </ul>
              )}
            </div>

            <div style={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: isMobile ? '8px' : '20px'
            }}>
              {isMobile && (
                <Button
                  type="text"
                  icon={<IconMenu />}
                  onClick={() => setMobileMenuVisible(!mobileMenuVisible)}
                  style={{ padding: '4px' }}
                />
              )}
              
              <LanguageSwitch />
              
              {isLoggedIn ? (
                <Dropdown
                  droplist={
                    <Menu style={{ 
                      minWidth: '200px',
                      padding: '8px 0',
                    }}>
                      <Menu.Item key="logout" onClick={handleLogout}>
                        {t('nav.logout')}
                      </Menu.Item>
                    </Menu>
                  }
                  position="br"
                >
                  <Button 
                    type="text" 
                    style={{ 
                      color: '#3491FA',
                      fontSize: '14px',
                      padding: '4px 8px',
                      borderRadius: '8px',
                      maxWidth: isMobile ? '120px' : 'none',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {userEmail}
                  </Button>
                </Dropdown>
              ) : (
                <Button 
                  type="outline" 
                  onClick={() => setLoginVisible(true)}
                  style={{
                    fontSize: '14px',
                    height: '32px',
                    padding: '0 15px',
                    borderRadius: '8px',
                    color: '#001A4D',
                    borderColor: '#001A4D'
                  }}
                >
                  {t('nav.login')}
                </Button>
              )}
            </div>
          </nav>
          
          <AnimatePresence>
            {isMobile && mobileMenuVisible && <MobileMenu />}
          </AnimatePresence>

          <GoldenInviteBox 
            onClick={() => setInvitationVisible(true)} 
            setLoginVisible={setLoginVisible}
            isLoggedIn={isLoggedIn}
          />
          
          <FloatingButtons 
            isLoggedIn={isLoggedIn}
            remainingEnglishWords={remainingEnglishWords}
            remainingChineseChars={remainingChineseChars}
            setHistoryVisible={handleHistoryOpen}
            onNewCheck={handleNewCheck}
          />

          <Routes>
            <Route path="/" element={
              <>
                <div style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  padding: isMobile ? '10px 0 60px' : '20px 0 100px', // 移除水平padding
                  position: 'relative',
                  zIndex: 2,
                  background: 'transparent',
                  width: '100%', // 确保宽度100%
                }}>
                  <WavyBackground 
                    blur={8}
                    waveOpacity={0.5}
                  />
                  
                  <div style={{
                    textAlign: 'center',
                    marginTop: isMobile ? '10px' : '15px',
                    marginBottom: isMobile ? '10px' : '15px',
                    width: '100%',
                    maxWidth: '900px',
                    position: 'relative',
                    zIndex: 3,
                    padding: isMobile ? '0 15px' : '0 20px',
                  }}>
                    {isLoggedIn ? (
                      <></>
                    ) : (
                      <>
                        <Title heading={2} style={{ 
                          color: TITLE_COLOR, 
                          marginBottom: isMobile ? '15px' : '20px',
                          fontSize: isMobile ? '28px' : 'clamp(32px, 6vw, 40px)',
                          lineHeight: isMobile ? '1.3' : '1.4',
                          display: 'flex',              
                          flexDirection: 'column',     
                          alignItems: 'center',         
                          justifyContent: 'center',     
                          gap: isMobile ? '5px' : '10px',
                          width: '100%', 
                        }}>
                          <motion.div
                            variants={typingContainer}
                            initial="hidden"
                            animate="show"
                            style={{
                              display: 'flex',
                              flexDirection: isMobile ? 'column' : 'row',
                              alignItems: 'center',
                              justifyContent: 'center',
                              gap: isMobile ? '5px' : '10px',
                              width: isMobile ? '100%' : 'auto',
                            }}
                          >
                            <div style={{ 
                              display: 'flex', 
                              gap: 0,
                              justifyContent: 'center'
                            }}>
                              {Array.from(t('hero.trusted')).map((char, index) => (
                                <motion.span
                                  key={`trusted-${index}`}
                                  variants={typingCharacter}
                                  style={{ display: 'inline-block' }}
                                >
                                  {char}
                                </motion.span>
                              ))}
                            </div>
                            
                            <motion.span style={{
                              color: '#3491FA',
                              borderRadius: '4px',
                              fontWeight: 'bold',
                              display: 'flex',
                              justifyContent: 'center',
                              whiteSpace: isMobile ? 'normal' : 'nowrap',
                              margin: isMobile ? '0' : '0 10px'
                            }}>
                              {Array.from(t('hero.bypass')).map((char, index) => (
                                <motion.span
                                  key={`bypass-${index}`}
                                  variants={typingCharacter}
                                  style={{
                                    display: 'inline-block',
                                    whiteSpace: 'pre'
                                  }}
                                >
                                  {char}
                                </motion.span>
                              ))}
                            </motion.span>

                            <div style={{ 
                              display: 'flex', 
                              gap: 0,
                              justifyContent: 'center'
                            }}>
                              {Array.from(t('hero.tool')).map((char, index) => (
                                <motion.span
                                  key={`tool-${index}`}
                                  variants={typingCharacter}
                                  style={{ display: 'inline-block' }}
                                >
                                  {char}
                                </motion.span>
                              ))}
                            </div>
                          </motion.div>
                        </Title>

                        <motion.p
                          initial={{ opacity: 0, y: -20 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{
                            duration: 1,
                            delay: 2.2
                          }}
                          style={{ 
                            color: TITLE_COLOR, 
                            fontSize: isMobile ? '16px' : '18px',
                            wordBreak: 'break-word', 
                            maxWidth: isMobile ? '100%' : '900px',
                            margin: '0 auto',
                            padding: isMobile ? '0 10px' : '0',
                            lineHeight: '1.6',
                            textAlign: 'center',
                          }}>
                          {t('hero.description')}
                        </motion.p>
                      </>
                    )}

                    <motion.div
                      initial={{ opacity: 0, y: -40 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ 
                        duration: 0.8,
                        delay: isLoggedIn ? 0 : 2.5,
                        ease: [0.4, 0, 0.2, 1],
                      }}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '8px',
                        marginTop: isLoggedIn ? '40px' : '30px',
                      }}
                    >
                      <DetectorTabs
                        activeTab={detector}
                        onChange={setDetector}
                      />
                      
                      {isLoggedIn && (
                        <div style={{
                          color: '#4E5969',
                          fontSize: '14px',
                        }}>
                          {t(`aiChecker.detectorDescription.${detector}`)}
                        </div>
                      )}
                    </motion.div>
                  </div>
                  <div style={{
                    position: 'relative',
                    zIndex: 3,
                    width: '100%',
                    maxWidth: '1200px',
                    display: 'flex',
                    justifyContent: 'center',
                    padding: isMobile ? '0 15px' : '0 20px',
                  }}>
                    <AITextChecker 
                      className="ai-text-checker" 
                      isLoggedIn={isLoggedIn} 
                      detector={detector}
                      onWordsUpdate={(type, newCount) => {
                        if (type === 'english') {
                          setRemainingEnglishWords(newCount);
                        } else {
                          setRemainingChineseChars(newCount);
                        }
                      }}
                      remainingEnglishWords={remainingEnglishWords}
                      remainingChineseChars={remainingChineseChars}
                      showLogin={() => setLoginVisible(true)}
                    />
                  </div>
                </div>
                <div id="features" style={{
                  width: '100%',
                  background: '#E8F3FF',
                  padding: isMobile ? '20px 0' : '30px 0',
                  display: 'flex',           // 添加 flex 布局
                  justifyContent: 'center',  // 水平居中
                  alignItems: 'center',      // 垂直居中
                }}> 
                  <div style={{
                    width: '100%',
                    maxWidth: '1200px',
                    margin: '0 auto',
                    padding: isMobile ? '0 15px' : '0 20px',
                    display: 'flex',           // 添加 flex 布局
                    justifyContent: 'center',  // 水平居中
                  }}>
                    <Features />
                  </div>
                </div>
                <div style={{
                  width: '100%',
                  padding: isMobile ? '20px 0' : '30px 0',
                  background: 'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(232,243,255,0.3) 50%, rgba(232,243,255,1) 100%)',
                  display: 'flex',           // 添加 flex 布局
                  justifyContent: 'center',  // 水平居中
                  alignItems: 'center',      // 垂直居中
                }}>
                  <div style={{
                    width: '100%',
                    maxWidth: '1200px',
                    margin: '0 auto',
                    padding: isMobile ? '0 15px' : '0 20px',
                    display: 'flex',           // 添加 flex 布局
                    justifyContent: 'center',  // 水平居中
                  }}>
                    <Pricing 
                      onLoginRequired={handleLoginRequired}
                      detector={detector}
                    />
                  </div>
                </div>
              </>
            } />
            <Route path="/payment-success" element={<PaymentSuccess />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>

          <Footer />

          <AnimatePresence>
            {invitationVisible && (
              <>
                {/* 背景遮罩 */}
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                  style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    background: 'rgba(255, 255, 255, 0.9)',
                    backdropFilter: 'blur(4px)',
                    zIndex: 1000,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflowY: 'hidden'  // 确保遮罩层也不会滚动
                  }}
                  onClick={() => setInvitationVisible(false)}
                >
                  {/* 内容区域 */}
                  <motion.div
                    initial={{ opacity: 0, scale: 0.95 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.95 }}
                    transition={{ duration: 0.2 }}
                    style={{
                      width: '90%',
                      maxWidth: '500px',
                      position: 'relative',
                      zIndex: 1001
                    }}
                    onClick={e => e.stopPropagation()}  // 防止点击内容区域关闭弹窗
                  >
                    <Button
                      type="text"
                      icon={<IconClose />}
                      style={{
                        position: 'absolute',
                        right: '12px',
                        top: '12px',
                        padding: '4px',
                        minWidth: 'auto',
                        zIndex: 1,
                        background: 'white',
                        borderRadius: '50%',
                        boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                      }}
                      onClick={() => setInvitationVisible(false)}
                    />
                    <InvitationCode />
                  </motion.div>
                </motion.div>
              </>
            )}
          </AnimatePresence>
          
          <AnimatePresence>
            {loginVisible && (
              <Login 
                onSuccess={() => {
                  setLoginVisible(false);
                  setIsLoggedIn(true);
                  setUserEmail(localStorage.getItem('userEmail'));
                  fetchRemainingWords();
                  // 清除存储的邀请码
                  localStorage.removeItem('pendingInviteCode');
                }}
                onClose={() => {
                  setLoginVisible(false);
                  // 清除存储的邀请码
                  localStorage.removeItem('pendingInviteCode');
                }}
                // 只有在有 pendingInviteCode 时才默认显示注册页
                defaultActiveTab={localStorage.getItem('pendingInviteCode') ? 'register' : 'login'}
              />
            )}
          </AnimatePresence>
          
          <WritingHistory 
            visible={historyVisible}
            onClose={() => setHistoryVisible(false)}
            history={writingHistory}
            loading={historyLoading}
            error={historyError}
          />
        </div>
      </ConfigProvider>
    </Router>
  );
};

export default App;
