import React, { useState, useEffect } from 'react';
import { Typography } from '@arco-design/web-react';
import { useLanguage } from '../contexts/LanguageContext';

const { Title, Paragraph } = Typography;

const HeroFeature = ({ title, description, children }) => (
  <div style={{
    backgroundColor: 'white',
    borderRadius: '32px',
    padding: '48px 20px',
    marginBottom: '48px',
    border: '1px solid #E8F3FF',
    width: '100%',
    boxSizing: 'border-box',
  }}>
    <div style={{
      maxWidth: '800px',
      margin: '0 auto 32px',
      textAlign: 'center',
    }}>
      <div
        style={{
          color: '#001A4D',
          fontSize: '32px',
          marginBottom: '16px',
          fontWeight: '600',
          wordBreak: 'keep-all',
        }}
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <Paragraph style={{
        color: '#4E5969',
        fontSize: '16px',
        lineHeight: '1.6',
        wordBreak: 'keep-all',
      }}>
        {description}
      </Paragraph>
    </div>
    {children}
  </div>
);

const FeatureCard = ({ title, description, children }) => (
  <div style={{
    backgroundColor: 'white',
    borderRadius: '24px',
    padding: '40px',
    marginBottom: '40px',
    boxShadow: '0 8px 24px rgba(0,0,0,0.06)',
    transition: 'transform 0.3s ease',
    ':hover': {
      transform: 'translateY(-4px)',
    },
  }}>
    <Title heading={3} style={{ 
      color: '#001A4D', 
      fontSize: '28px',
      marginBottom: '20px',
      fontWeight: '600',
      textAlign: 'center'
    }}>
      {title}
    </Title>
    <Paragraph style={{ 
      color: '#4E5969', 
      fontSize: '16px', 
      lineHeight: '1.6',
      marginBottom: '32px',
      textAlign: 'center'
    }}>
      {description}
    </Paragraph>
    {children}
  </div>
);

const FeatureSection = ({ features, activeFeature, setActiveFeature }) => (
  <div style={{
    display: 'grid',
    gridTemplateColumns: '300px 1fr',
    gap: '24px',
    background: 'white',
    borderRadius: '16px',
    overflow: 'hidden',
    border: '1px solid #E8F3FF',
  }}>
    <div style={{
      borderRight: '1px solid #E8F3FF',
      background: '#F7FAFF',
    }}>
      {features.map((feature, index) => (
        <button
          key={index}
          onClick={() => setActiveFeature(index)}
          style={{
            width: '100%',
            padding: '24px',
            border: 'none',
            background: activeFeature === index ? 'white' : 'transparent',
            borderLeft: `4px solid ${activeFeature === index ? '#3491FA' : 'transparent'}`,
            textAlign: 'center',
            cursor: 'pointer',
            transition: 'all 0.3s ease',
          }}
        >
          <div style={{
            fontSize: '16px',
            fontWeight: '500',
            color: activeFeature === index ? '#3491FA' : '#4E5969',
          }}>
            {feature.title}
          </div>
        </button>
      ))}
    </div>

    <div style={{
      padding: '24px',
    }}>
      <img
        src={features[activeFeature].image}
        alt={features[activeFeature].title}
        style={{
          width: '100%',
          borderRadius: '8px',
        }}
      />
    </div>
  </div>
);

const ComparisonItem = ({ issue, solution }) => {
  const isMobile = useIsMobile();

  return (
    <div style={{
      background: 'white',
      borderRadius: '16px',
      padding: isMobile ? '16px' : '24px',
      marginBottom: '16px',
      border: '1px solid #E8F3FF',
      display: 'grid',
      gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr',
      gap: isMobile ? '12px' : '24px',
      alignItems: 'center',
    }}>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
      }}>
        <span style={{
          width: '24px',
          height: '24px',
          borderRadius: '50%',
          background: '#FFF1F0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '14px',
          color: '#FF4D4F',
          flexShrink: 0,
        }}>
          ✕
        </span>
        <span style={{
          color: '#2E3A59',
          fontSize: isMobile ? '14px' : '15px',
          fontWeight: '500',
          lineHeight: '1.4',
        }}>
          {issue}
        </span>
      </div>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
      }}>
        <span style={{
          width: '24px',
          height: '24px',
          borderRadius: '50%',
          background: '#E8F3FF',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '14px',
          color: '#3491FA',
          flexShrink: 0,
        }}>
          ✓
        </span>
        <span style={{
          color: '#3491FA',
          fontSize: isMobile ? '14px' : '15px',
          fontWeight: '500',
          lineHeight: '1.4',
        }}>
          {solution}
        </span>
      </div>
    </div>
  );
};

const SimpleCarousel = ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prev) => (prev + 1) % items.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prev) => (prev - 1 + items.length) % items.length);
  };

  const buttonStyle = {
    width: '48px',
    height: '48px',
    borderRadius: '50%',
    border: 'none',
    background: '#3491FA',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 1,
    boxShadow: '0 2px 8px rgba(52,145,250,0.25)',
    ':hover': {
      background: '#2b7ad9',  // 稍微深一点的蓝色作为悬停状态
    }
  };

  return (
    <div style={{
      background: 'white',
      borderRadius: '24px',
      padding: '32px',
      border: '1px solid #E8F3FF',
    }}>
      <div style={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        gap: '24px',
      }}>
        {/* 上一张按钮 */}
        {currentIndex > 0 && (
          <button
            onClick={prevSlide}
            style={{
              ...buttonStyle,
              left: '-24px',
            }}
          >
            <span style={{
              color: 'white',  // 改为白色
              fontSize: '20px',
            }}>←</span>
          </button>
        )}

        {/* 修改图片展示区域 */}
        <div style={{
          flex: 1,
          position: 'relative',
          aspectRatio: '16/9',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          overflow: 'hidden',
          background: 'white',
          borderRadius: '12px',
        }}>
          <img
            src={items[currentIndex].image}
            alt={items[currentIndex].title}
            style={{
              width: '100%',
              height: 'auto',
              objectFit: 'contain',
              objectPosition: 'top',
              transition: 'opacity 0.3s ease',
            }}
          />
          <div style={{
            position: 'absolute',
            bottom: '16px',
            left: '50%',
            transform: 'translateX(-50%)',
            background: '#3491FA',
            padding: '8px 20px',
            borderRadius: '20px',
            fontSize: '14px',
            color: 'white',
            fontWeight: '500',
            boxShadow: '0 2px 8px rgba(52,145,250,0.25)',
            border: 'none',
            zIndex: 1,
          }}>
            {items[currentIndex].title}
          </div>
        </div>

        {/* 下一张按钮 */}
        {currentIndex < items.length - 1 && (
          <button
            onClick={nextSlide}
            style={{
              ...buttonStyle,
              right: '-24px',
            }}
          >
            <span style={{
              color: 'white',  // 改为白色
              fontSize: '20px',
            }}>→</span>
          </button>
        )}
      </div>
    </div>
  );
};

// 添加一个自定义 hook 来检测屏幕宽度
const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
};

const Features = () => {
  const { t } = useLanguage();
  const isMobile = useIsMobile();

  const detectionFeatures = [
    {
      title: 'Turnitin',
      image: 'https://ushallpassmainland-1305187289.cos.accelerate.myqcloud.com/turnitin.webp'
    },
    {
      title: 'CNKI',
      image: 'https://ushallpassmainland-1305187289.cos.accelerate.myqcloud.com/cnki.webp'
    }
  ];

  const comparisonData = [
    {
      issue: t('features.rewriting.comparison.items.grammar.issue'),
      solution: t('features.rewriting.comparison.items.grammar.solution'),
    },
    {
      issue: t('features.rewriting.comparison.items.format.issue'),
      solution: t('features.rewriting.comparison.items.format.solution'),
    },
    {
      issue: t('features.rewriting.comparison.items.punctuation.issue'),
      solution: t('features.rewriting.comparison.items.punctuation.solution'),
    },
    {
      issue: t('features.rewriting.comparison.items.capitalization.issue'),
      solution: t('features.rewriting.comparison.items.capitalization.solution'),
    },
  ];

  const examples = [
    {
      title: t('features.rewriting.examples.tool1'),
      image: 'https://ushallpassmainland-1305187289.cos.accelerate.myqcloud.com/hix.webp'
    },
    {
      title: t('features.rewriting.examples.tool2'),
      image: 'https://ushallpassmainland-1305187289.cos.accelerate.myqcloud.com/stealth.webp'
    },
    {
      title: t('features.rewriting.examples.tool3'),
      image: 'https://ushallpassmainland-1305187289.cos.accelerate.myqcloud.com/ud.webp'
    }
  ];

  return (
    <div style={{ 
      width: '100%',
      maxWidth: '1200px', 
      margin: '0 auto',
      padding: '60px 15px',
      overflow: 'hidden',
    }}>
      {/* 添加整体标题 */}
      <div style={{
        textAlign: 'center',
        marginBottom: '60px',
      }}>
        <Title heading={2} style={{ 
          color: '#001A4D',
          fontSize: '40px',
          marginBottom: '20px',
          fontWeight: '600',
          wordBreak: 'keep-all',
        }}>
          {t('features.title')}
        </Title>
        {/* <Paragraph style={{
          color: '#4E5969',
          fontSize: '18px',
          lineHeight: '1.6',
          maxWidth: '750px',
          margin: '0 auto',
          wordBreak: 'keep-all',
        }}>
          {t('features.description')}
        </Paragraph> */}
      </div>

      {/* 第一部分：检测功能 */}
      <HeroFeature
        title={t('features.detection.title')}
        description={t('features.detection.description')}
      >
        <SimpleCarousel items={detectionFeatures} />
      </HeroFeature>

      {/* 第二部分：重写功能 */}
      <div style={{
        backgroundColor: 'white',
        borderRadius: '32px',
        padding: '48px 20px',
        border: '1px solid #E8F3FF',
        width: '100%',
        boxSizing: 'border-box',
      }}>
        <div style={{
          maxWidth: '800px',
          margin: '0 auto 32px',
          textAlign: 'center',
        }}>
          <div
            style={{
              color: '#001A4D',
              fontSize: '32px',
              marginBottom: '16px',
              fontWeight: '600',
              wordBreak: 'keep-all',
            }}
            dangerouslySetInnerHTML={{ __html: t('features.rewriting.title') }}
          />
          <Paragraph style={{
            color: '#4E5969',
            fontSize: '16px',
            lineHeight: '1.6',
            wordBreak: 'keep-all',
          }}>
            {t('features.rewriting.description')}
          </Paragraph>
        </div>

        {/* 对比表格 */}
        <div style={{
          background: '#F7F8FA',
          borderRadius: '24px',
          padding: isMobile ? '20px 16px' : '32px 56px',
          marginBottom: '48px',
          border: '1px solid #E8F3FF',
        }}>
          <div style={{
            display: 'grid',
            gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr',
            gap: isMobile ? '12px' : '24px',
            padding: isMobile ? '0 12px 12px' : '0 24px 16px',
            borderBottom: '2px solid #E8F3FF',
            marginBottom: isMobile ? '16px' : '24px',
          }}>
            <div style={{ 
              color: '#001A4D', 
              fontWeight: '600',
              fontSize: isMobile ? '14px' : '16px',
              textAlign: 'center',
            }}>
              {t('features.rewriting.comparison.header.other')}
            </div>
            <div style={{ 
              color: '#001A4D', 
              fontWeight: '600',
              fontSize: isMobile ? '14px' : '16px',
              textAlign: 'center',
            }}>
              {t('features.rewriting.comparison.header.ushallpass')}
            </div>
          </div>
          {comparisonData.map((item, index) => (
            <ComparisonItem 
              key={index} 
              issue={item.issue}
              solution={item.solution}
            />
          ))}
        </div>

        {/* 使用相同的轮播组件 */}
        <SimpleCarousel items={examples} />
      </div>
    </div>
  );
};

export default Features;